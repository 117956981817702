import React, { useEffect, useMemo, useState } from "react";
import { Maintenance, UserInfo } from "../../@types";
import { useQuery } from "@tanstack/react-query";
import { getMaintenanceList } from "../../services/user-admin.service";
import TableHeader from "../UserTable";
import { ITEMS_PER_PAGE } from "../../constants";
import { useMaintenanceDownloader } from "../../hooks/useMaintenanceDownloader";

interface MaintenanceTableProps {
  data: UserInfo | null;
}

const MaintenanceTable: React.FC<MaintenanceTableProps> = ({ data }) => {
  const maintenanceHeaders = [
    "Id",
    "CreatedAt",
    "Technician Name",
    "Client Name",
    "status",
    "actions",
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState("updatedAt");
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">("DESC");
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const { downloadMaintenanceReport } = useMaintenanceDownloader();
  const id = data?.userId || 0;

  const {
    data: maintenances,
    refetch: refetchMaintenanceList,
    isLoading,
  } = useQuery({
    queryKey: [
      "maintenances",
      id,
      currentPage,
      limit,
      sortBy,
      sortOrder,
      search,
      status,
    ],
    queryFn: () =>
      getMaintenanceList(
        id,
        currentPage,
        limit,
        sortBy,
        sortOrder,
        search,
        status
      ),
    onSuccess: (response: any) => {
      setTotal(response.total);
    },
    enabled: !!data,
  });

  useEffect(() => {
    refetchMaintenanceList();
  }, [currentPage, limit, sortBy, sortOrder, search, status]);

  const totalPages = Math.ceil(total / ITEMS_PER_PAGE);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setStatus(e.target.value);
    setCurrentPage(1);
  };

  const handleDownloadClick = (maintenanceId: number) => {
    downloadMaintenanceReport(maintenanceId);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {" "}
      <div className="my-8">
        <div className="mb-4 flex justify-between items-center">
          <input
            type="text"
            placeholder="Search"
            value={search}
            onChange={handleSearchChange}
            className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <div>
            <div>Status:</div>
            <select
              value={status}
              onChange={handleStatusChange}
              className="px-8 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">All</option>
              <option value="SUBMITTED">Submitted</option>
              <option value="CREATED">Created</option>
            </select>
          </div>
        </div>
        <table className="border border-Grey-light bg-white rounded-md w-full text-left table-auto border-collapse ">
          <TableHeader headers={maintenanceHeaders} />

          <tbody>
            {maintenances.data?.map((item: Maintenance, index: number) => (
              <tr
                key={index}
                className="hover:bg-gray-100 transition-colors duration-200"
              >
                <td className="px-4 py-2 border-l border-b border-r border-black">
                  {item.id}
                </td>
                <td className="px-4 py-2 border-l border-b border-r border-black">
                  {new Date(item.createdAt).toLocaleDateString()}
                </td>
                <td className="px-4 py-2 border-b border-r border-black">
                  {item?.technician.name}
                </td>
                <td className="px-4 py-2 border-b border-r border-black">
                  {item?.client?.name}
                </td>
                <td className="px-4 py-2 border-b border-r border-black">
                  {item?.status}
                </td>
                <td className="px-4 py-2 border-b border-r border-black">
                  <button
                    type="button"
                    onClick={() =>
                      item.status === "SUBMITTED" &&
                      item.id !== null &&
                      handleDownloadClick(item.id)
                    }
                    className={`bg-gray-500 border border-black text-white py-1 px-2 mb-4 mr-4 rounded-md ${
                      item.status === "SUBMITTED"
                        ? "hover:bg-blue-500"
                        : "cursor-not-allowed opacity-50"
                    }`}
                    disabled={item.status !== "SUBMITTED"}
                  >
                    {"Download"}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-end space-x-2 mt-4">
          <button
            className="bg-white border border-black text-black px-2 py-1 rounded-md hover:bg-gray-100 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            {"<"}
          </button>
          {pageNumbers.map((number: number) => (
            <button
              key={number}
              onClick={() => handlePageChange(number)}
              disabled={number === currentPage}
              className={`bg-white border border-black text-black px-2 py-1 rounded-md hover:bg-gray-100 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm ${
                number === currentPage ? "opacity-50 cursor-default" : ""
              }`}
            >
              {number}
            </button>
          ))}
          <button
            className="bg-white border border-black text-black px-2 py-1 rounded-md hover:bg-gray-100 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            {">"}
          </button>
        </div>
      </div>
    </>
  );
};

export default MaintenanceTable;
