import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  assignUser,
  fetchAdmins,
  getTickets,
  updateTicket,
} from "../../services/user-admin.service";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useNavigate } from "react-router-dom";
import { TicketPriority, TicketStatus, TicketType } from "../../@types";

function CustomerSupport() {
  const [admins, setAdmins] = useState<{ id: number; name: string }[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [showActionsMap, setShowActionsMap] = useState<{
    [key: number]: boolean;
  }>({});

  const [showModal, setShowModal] = useState<{ [key: string]: boolean }>({
    status: false,
    priority: false,
    type: false,
    assignUser: false,
  });
  const navigate = useNavigate();

  const [currentTicket, setCurrentTicket] = useState<any | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [selectedType, setSelectedType] = useState<string>("");
  const [selectedPriority, setSelectedPriority] = useState<string>("");
  const [sortBy, setSortBy] = useState<string>("createdAt");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");

  const [filters, setFilters] = useState({
    status: selectedStatus,
    priority: selectedPriority,
    type: selectedType,
    page: 1,
  });

  const {
    data: ticketData,
    isLoading,
    error,
    refetch,
  } = useQuery<any>(["ticketData", filters, sortBy, sortOrder], () =>
    getTickets(filters, sortBy, sortOrder)
  );

  const { mutate: updateTicketMutation } = useMutation(
    (data: {
      id: number;
      updateData: { status?: string; priority?: string; type?: string };
    }) => updateTicket(data.id, data.updateData),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const { mutate: assignUserMutation } = useMutation(
    (data: { ticketId: number; userId: number }) =>
      assignUser(data.ticketId, data.userId),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  useEffect(() => {
    fetchAdmins().then(setAdmins).catch(console.error);
  }, []);

  useEffect(() => {
    setFilters((prev) => ({ ...prev, page: 1 }));
  }, [filters.status, filters.priority, filters.type]);

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handlePageChange = (newPage: number) => {
    setFilters((prev) => ({
      ...prev,
      page: newPage,
    }));
  };

  const handleUpdateTicket = (
    ticketId: number,
    updateData: { status?: string; priority?: string; type?: string }
  ) => {
    updateTicketMutation({ id: ticketId, updateData });
    setShowModal((prev) => ({
      ...prev,
      status: false,
      priority: false,
      type: false,
    }));
  };

  const toggleActions = (ticketId: number) => {
    setShowActionsMap((prev) => ({
      ...prev,
      [ticketId]: !prev[ticketId],
    }));
  };

  const handleAssignUser = (ticketId: number) => {
    if (selectedUserId) {
      assignUserMutation({ ticketId, userId: selectedUserId });
      setShowModal((prev) => ({ ...prev, assignUser: false }));
    }
  };

  const getPaginationNumbers = (currentPage: number, pageCount: number) => {
    const delta = 2;
    const range = [];
    const left = Math.max(1, currentPage - delta);
    const right = Math.min(pageCount, currentPage + delta);

    for (let i = left; i <= right; i++) {
      range.push(i);
    }

    if (left > 1) {
      range.unshift(1, "...");
    }

    if (right < pageCount) {
      range.push("...", pageCount);
    }

    return range.filter((value, index, self) => self.indexOf(value) === index);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading tickets.</div>;
  }

  const handleSortOrderChange = () => {
    setSortOrder((prev) => (prev === "asc" ? "desc" : "asc"));
  };

  const currentPage = filters.page;
  const totalPages = ticketData.pageCount;
  const pageNumbers = getPaginationNumbers(currentPage, totalPages);

  return (
    <div className="bg-blue-light text-black p-6">
      <div className="mb-8">
        <h2 className="text-lg mb-4 font-bold">Filters:</h2>

        <div className="mb-4 flex items-center space-x-8 p-2">
          {/* Filters */}
          <select
            name="status"
            onChange={handleFilterChange}
            className="pl-4 pr-10 py-3 bg-white border border-gray-300 rounded-md text-left"
            value={filters.status}
          >
            <option value="">All Statuses</option>
            {Object.values(TicketStatus).map((status) => (
              <option key={status} value={status}>
                {status}
              </option>
            ))}
          </select>

          <select
            name="priority"
            onChange={handleFilterChange}
            className="pl-4 pr-10 py-3 bg-white border border-gray-300 rounded-md text-left"
            value={filters.priority}
          >
            <option value="">All Priorities</option>
            {Object.values(TicketPriority).map((priority) => (
              <option key={priority} value={priority}>
                {priority}
              </option>
            ))}
          </select>

          <select
            name="type"
            onChange={handleFilterChange}
            className="pl-4 pr-10 py-3 bg-white border border-gray-300 rounded-md text-left"
            value={filters.type}
          >
            <option value="">All Types</option>
            {Object.values(TicketType).map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>

          {/* Sort by */}
          <div
            onClick={handleSortOrderChange}
            className={`flex items-center cursor-pointer space-x-2 ${
              sortBy === "createdAt" ? "text-black-500" : "text-gray-500"
            }`}
          >
            <span>
              {sortOrder === "asc" ? (
                <ArrowUpwardIcon className="w-5 h-5" />
              ) : (
                <ArrowDownwardIcon className="w-5 h-5" />
              )}
            </span>
            <span className="text-black">Sort by Date</span>
          </div>
        </div>

        {/* Feedback List */}
        <div className="space-y-4 border border-black rounded-md shadow-lg p-2">
          {ticketData?.items?.map((item: any) => (
            <div
              key={item.id}
              className={`p-4 border-2 rounded-md shadow-sm bg-white flex justify-between
                 ${item.status === "Review" ? "border-blue-500" : ""}
              ${item.status === TicketStatus.RESOLVED ? "border-green-500" : ""}
              ${
                (item.priority === "High" && item.status !== TicketStatus.RESOLVED) ||
                (item.priority === "Critical" && item.status !== TicketStatus.RESOLVED)
                  ? "border-red-600"
                  : ""
              }
              ${item.priority === "Medium" ? "border-orange-300" : ""}
              ${
                !["High", "Critical", "Medium"].includes(item.priority) &&
                item.status !== TicketStatus.RESOLVED
                  ? "border-gray-300"
                  : ""
              }
            `}
            >
              <div className="flex-1 bg-white p-4 border border-gray-500 rounded-md shadow-sm">
                <div
                  className="font-semibold text-lg text-gray-900 hover:bg-gray-100 transition-colors duration-200 cursor-pointer"
                  onClick={() => {
                    navigate(`/users/${item.user.id}`);
                  }}
                >
                  Submitted by:{" "}
                  <span className="font-normal text-gray-700">
                    {item.user.name}
                  </span>
                </div>
                <div className="text-sm text-gray-500 mb-4">
                  {new Date(item.createdAt).toLocaleDateString()}{" "}
                  {new Date(item.createdAt).toLocaleTimeString()}
                </div>
                <div
                  className="text-gray-800 mb-4 text-base border border-gray-300 rounded-md shadow-sm p-2 hover:bg-gray-100 transition-colors duration-200 cursor-pointer"
                  onClick={() => {
                    navigate(`/ticket/${item.id}`);
                  }}
                >
                  Title: {item.title}
                </div>

                <div
                  className={`text-sm font-medium mb-2 ${getStatusColor(
                    item.status
                  )}`}
                >
                  <span className="font-semibold text-gray-900">Status:</span>{" "}
                  {item.status || "Not Assigned"}
                </div>
                <div
                  className={`text-sm font-medium mb-2 ${getPriorityColor(
                    item.priority
                  )}`}
                >
                  <span className="font-semibold text-gray-900">Priority:</span>{" "}
                  {item.priority || "Not Assigned"}
                </div>
                <div className="text-sm text-gray-600 mb-4">
                  <span className="font-semibold text-gray-900">Type:</span>{" "}
                  {item.type || "Not Assigned"}
                </div>
                <div className="flex justify-end text-sm text-gray-600">
                  <span className="font-semibold text-gray-900 mr-2">
                    Assigned Admin:
                  </span>
                  {item.assignedToUser
                    ? item.assignedToUser.name
                    : "Not Assigned"}
                </div>
              </div>

              <div className="flex flex-col justify-between ml-4">
                {/* Toggle Actions Button */}
                <button
                  className="bg-white text-black px-4 py-2 rounded-md mb-2 hover:bg-gray-300 transition-colors duration-200 group"
                  onClick={() => toggleActions(item.id)}
                >
                  <span className="underline">
                    {showActionsMap[item.id] ? "Hide Actions" : "Show Actions"}
                  </span>
                </button>

                {/* Conditional Rendering of Action Buttons */}
                {showActionsMap[item.id] && (
                  <>
                    <button
                      className="bg-white text-black border border-black px-4 py-2 rounded-md mb-2 hover:bg-gray-300 transition-colors duration-200"
                      onClick={() => {
                        setCurrentTicket(item);
                        setShowModal((prev) => ({ ...prev, status: true }));
                      }}
                    >
                      Update Status
                    </button>
                    <button
                      className="bg-white text-black border border-black px-4 py-2 rounded-md mb-2 hover:bg-gray-300 transition-colors duration-200"
                      onClick={() => {
                        setCurrentTicket(item);
                        setShowModal((prev) => ({ ...prev, priority: true }));
                      }}
                    >
                      Update Priority
                    </button>
                    <button
                      className="bg-white text-black border border-black px-4 py-2 rounded-md mb-2 hover:bg-gray-300 transition-colors duration-200"
                      onClick={() => {
                        setCurrentTicket(item);
                        setShowModal((prev) => ({ ...prev, type: true }));
                      }}
                    >
                      Update Type
                    </button>
                    <button
                      className="bg-white text-black border border-black px-4 py-2 rounded-md mb-2 hover:bg-gray-300 transition-colors duration-200"
                      onClick={() => {
                        setCurrentTicket(item);
                        setShowModal((prev) => ({ ...prev, assignUser: true }));
                      }}
                    >
                      Assign Admin
                    </button>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>

        {/* Pagination */}
        <div className="flex justify-end space-x-2 mt-4">
          <button
            className="bg-white border border-black text-black px-2 py-1 rounded-md hover:bg-gray-100 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            {"<"}
          </button>
          {pageNumbers.map((number, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(number as number)}
              disabled={number === currentPage || number === "..."}
              className={`bg-white border border-black text-black px-2 py-1 rounded-md hover:bg-gray-100 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm ${
                number === currentPage ? "opacity-50 cursor-default" : ""
              }`}
            >
              {number}
            </button>
          ))}
          <button
            className="bg-white border border-black text-black px-2 py-1 rounded-md hover:bg-gray-100 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            {">"}
          </button>
        </div>
      </div>

      {/* Modals */}
      {currentTicket && (
        <>
          {/* Status Modal */}
          {showModal.status && (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
              <div className="bg-white p-6 rounded-md shadow-md w-80">
                <h3 className="text-lg font-bold mb-4">Update Status</h3>
                <select
                  className="px-4 py-2 bg-white border border-gray-300 rounded-md mb-4 w-full"
                  onChange={(e) => setSelectedStatus(e.target.value)}
                  value={selectedStatus}
                >
                  <option value="">Select Status</option>
                  {Object.values(TicketStatus).map((status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
                <div className="flex justify-end space-x-2">
                  <button
                    className={`px-4 py-2 rounded-md transition-colors duration-200 ${
                      selectedStatus
                        ? "bg-green-900 text-white hover:bg-green-700"
                        : "bg-gray-300 border border-black text-gray-500 cursor-not-allowed"
                    }`}
                    onClick={() =>
                      handleUpdateTicket(currentTicket.id, {
                        status: selectedStatus,
                      })
                    }
                    disabled={!selectedStatus}
                  >
                    Submit
                  </button>
                  <button
                    className="bg-white border border-black text-black px-4 py-2 rounded-md hover:bg-gray-300 transition-colors duration-200"
                    onClick={() => {
                      setShowModal((prev) => ({ ...prev, status: false }));
                      setSelectedStatus("");
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Priority Modal */}
          {showModal.priority && (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
              <div className="bg-white p-6 rounded-md shadow-md w-80">
                <h3 className="text-lg font-bold mb-4">Update Priority</h3>
                <select
                  className="px-4 py-2 bg-white border border-gray-300 rounded-md mb-4 w-full"
                  onChange={(e) => setSelectedPriority(e.target.value)}
                  value={selectedPriority}
                >
                  <option value="">Select Priority</option>
                  {Object.values(TicketPriority).map((priority) => (
                    <option key={priority} value={priority}>
                      {priority}
                    </option>
                  ))}
                </select>
                <div className="flex justify-end space-x-2">
                  <button
                    className={`px-4 py-2 rounded-md transition-colors duration-200 ${
                      selectedPriority
                        ? "bg-green-900 text-white hover:bg-green-700"
                        : "bg-gray-300 border border-black text-gray-500 cursor-not-allowed"
                    }`}
                    onClick={() =>
                      handleUpdateTicket(currentTicket.id, {
                        priority: selectedPriority,
                      })
                    }
                    disabled={!selectedPriority}
                  >
                    Submit
                  </button>
                  <button
                    className="bg-white border border-black text-black px-4 py-2 rounded-md hover:bg-gray-300 transition-colors duration-200"
                    onClick={() => {
                      setShowModal((prev) => ({ ...prev, priority: false }));
                      setSelectedPriority("");
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Type Modal */}
          {showModal.type && (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
              <div className="bg-white p-6 rounded-md shadow-md w-80">
                <h3 className="text-lg font-bold mb-4">Update Type</h3>
                <select
                  className="px-4 py-2 bg-white border border-gray-300 rounded-md mb-4 w-full"
                  onChange={(e) => setSelectedType(e.target.value)}
                  value={selectedType}
                >
                  <option value="">Select Type</option>
                  {Object.values(TicketType).map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
                <div className="flex justify-end space-x-2">
                  <button
                    className={`px-4 py-2 rounded-md transition-colors duration-200 ${
                      selectedType
                        ? "bg-green-900 text-white hover:bg-green-700"
                        : "bg-gray-300 border border-black text-gray-500 cursor-not-allowed"
                    }`}
                    onClick={() =>
                      handleUpdateTicket(currentTicket.id, {
                        type: selectedType,
                      })
                    }
                    disabled={!selectedType}
                  >
                    Submit
                  </button>
                  <button
                    className="bg-white border border-black text-black px-4 py-2 rounded-md hover:bg-gray-300 transition-colors duration-200"
                    onClick={() => {
                      setShowModal((prev) => ({ ...prev, type: false }));
                      setSelectedType("");
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Assign User Modal */}
          {showModal.assignUser && (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
              <div className="bg-white p-6 rounded-md shadow-md w-80">
                <h3 className="text-lg font-bold mb-4">Assign Admin</h3>
                <select
                  className="px-4 py-2 bg-white border border-gray-300 rounded-md mb-4 w-full"
                  onChange={(e) => setSelectedUserId(Number(e.target.value))}
                  value={selectedUserId || ""}
                >
                  <option value="">Select Admin</option>
                  {admins.map((admin) => (
                    <option key={admin.id} value={admin.id}>
                      {admin.name}
                    </option>
                  ))}
                </select>
                <div className="flex justify-end space-x-2">
                  <button
                    className={`px-4 py-2 rounded-md transition-colors duration-200 ${
                      selectedUserId
                        ? "bg-green-900 text-white hover:bg-green-700"
                        : "bg-gray-300 border border-black text-gray-500 cursor-not-allowed"
                    }`}
                    onClick={() => handleAssignUser(currentTicket.id)}
                    disabled={!selectedUserId}
                  >
                    Assign
                  </button>
                  <button
                    className="bg-white border border-black text-black px-4 py-2 rounded-md hover:bg-gray-300 transition-colors duration-200"
                    onClick={() => {
                      setShowModal((prev) => ({ ...prev, assignUser: false }));
                      setSelectedUserId(null);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

// Helper functions for color coding
const getStatusColor = (status: string) => {
  switch (status) {
    case TicketStatus.OPEN:
      return "text-blue-500";
    case TicketStatus.IN_PROGRESS:
      return "text-orange-500";
    case TicketStatus.RESOLVED:
      return "text-green-500";
    case TicketStatus.CLOSED:
      return "text-black";
    default:
      return "text-gray-500";
  }
};

const getPriorityColor = (priority: string) => {
  switch (priority) {
    case "Critical":
      return "text-red-900";
    case "High":
      return "text-red-500";
    case "Medium":
      return "text-orange-500";
    case "Low":
      return "text-yellow-500";
    default:
      return "text-gray-500";
  }
};

export default CustomerSupport;
