import { useEffect, useState } from "react";

import Login from "./pages/public/Login";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { IUser } from "./@types/users";
import { goToWindow } from "./services/utils";
import { useInterceptorResponse, useInterceptRequest } from "./hooks/useInterceptorRequest";
import { AuthContext } from "./contexts/users.context";
import { WrapperGuard } from "./guards/AccessGuard";
import Header from "./components/header";
import { UserAdminView } from "./pages";
import { getLoggedUser } from "./services";


const queryClient = new QueryClient();

function App() {
  const [user, setUser] = useState<IUser | null>(null);


  const logoutUser = () => {
    setUser(null);
    goToWindow("/");
    localStorage.removeItem("accessToken");
  };

  useInterceptRequest(user);

  useInterceptorResponse(user, logoutUser);

  const token: string | null = localStorage.getItem("accessToken");

  if (token && !user) {
    getLoggedUser(token).then(setUser);
  }

  return (
    <div className="flex flex-col h-screen">
      <QueryClientProvider client={queryClient}>
        <AuthContext.Provider value={{ user, setUser }}>
          <WrapperGuard
            accessCondition={!!user}
            guardView={<Login setUser={setUser} />}
            dependencies={[!!user]}
          >
            <Header setUser={setUser} />
            <UserAdminView />
          </WrapperGuard>
        </AuthContext.Provider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
