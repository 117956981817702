import React, { useEffect, useState } from "react";
import { Role } from "../../../@types/users";

interface UserRolePopupProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (role: Role) => void;
}

const UserRolePopup: React.FC<UserRolePopupProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  const [role, setRole] = useState<Role | "">("");

  useEffect(() => {
    if (!isOpen) {
      setRole("");
    }
  }, [isOpen]);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    console.log("Role selected:", event.target.value);
    setRole(event.target.value as Role);
  };

  const handleConfirm = (event: React.FormEvent) => {
    event.preventDefault();

    if (role) {
      onConfirm(role);
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-md p-6">
        <div className="mb-4">
          <label htmlFor="roleSelect" className="mr-2">
            Role:
          </label>
          <select
            id="roleSelect"
            value={role}
            onChange={handleSelectChange}
            className="border border-gray-300 rounded-md py-2 px-8"
          >
            <option value="" disabled>
              Select a role
            </option>
            {Object.values(Role).map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="flex space-x-4">
          <button
            type="button"
            className={`bg-blue-500 border border-black text-white py-1 px-2 rounded-md ${
              !role ? "cursor-not-allowed opacity-50" : "hover:bg-blue-800"
            }`}
            onClick={handleConfirm}
            disabled={!role}
          >
            Confirm
          </button>
          <button
            type="button"
            onClick={onClose}
            className="bg-gray-500 border border-black text-white py-1 px-2 rounded-md hover:bg-gray-800"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserRolePopup;
