import { useQuery } from '@tanstack/react-query';
import { getAllMemberships } from '../../services/user-admin.service';

export const useFetchMemberships = (page: number, limit: number, state?: string, search?: string) => {
  return useQuery(['memberships', page, limit, state, search], () =>
    getAllMemberships(page, limit, state, search), 
    {
      keepPreviousData: true,
    }
  );
};
