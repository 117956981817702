import React from "react";
import { UserInfo } from "../@types";

interface UserInfoHeaderProps {
  filteredUserInfoData: UserInfo;
}

const UserInfoHeader: React.FC<UserInfoHeaderProps> = ({
  filteredUserInfoData,
}) => {
  return (
    <div className="p-6">
      <div className="flex space-x-8 justify-center items-center w-full bg-gray-200 border border-gray-300 rounded-lg shadow-md p-6">
        <div className="flex-1 flex flex-col items-center">
          <div className="font-bold text-lg mb-4">Name</div>
          <div className="text-gray-700">
            {filteredUserInfoData?.name || "N/A"}
          </div>
        </div>
        <div className="flex-1 flex flex-col items-center">
          <div className="font-bold text-lg mb-4">Email</div>
          <div className="text-gray-700">
            {filteredUserInfoData?.email || "N/A"}
          </div>
        </div>
        <div className="flex-1 flex flex-col items-center">
          <div className="font-bold text-lg mb-4">Role</div>
          <div className="text-gray-700">
            {filteredUserInfoData?.role || "N/A"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfoHeader;
