import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../../contexts/users.context";
import { ITEMS_PER_PAGE } from "../../constants";
import { getUsersInfo } from "../../services/user-admin.service";

export function useUsersActions({
  usersState,
  sortBy,
  sortDirection,
}: {
  usersState: any;
  sortBy?: string;
  sortDirection?: string;
}) {
  const { loggedUser } = useAuth();

  const { searchQuery, currentPage, setCurrentPage, setUsers, setTotalCount } =
    usersState;

  const { data: usersData } = useQuery(
    ["users", searchQuery, currentPage, ITEMS_PER_PAGE, sortBy, sortDirection],
    () =>
      getUsersInfo(
        searchQuery,
        currentPage,
        ITEMS_PER_PAGE,
        loggedUser?.role || "",
        loggedUser?.id || 0,
        sortBy || "",
        sortDirection || ""
      ),
    {
      keepPreviousData: true,
      onSuccess: (data) => {
        console.log("received users: ", data);
        setUsers(data.users);
        setTotalCount(data.totalCount);
      },
    }
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return {
    usersData,
    handlePageChange,
  };
}
