import { UseMutateFunction } from "@tanstack/react-query";
import { Dispatch, SetStateAction } from "react";


export enum Role {
    ADMIN = 'ADMIN',
}

export interface IUser {
    id: number,
    email: string,
    role: Role,
};

export type IUserContext = {
    user: IUser | null,
    setUser: Dispatch<SetStateAction<IUser | null>> | null,
    login: UseMutateFunction<{ user: IUser; tokenData: { token: string; }; }, Error, { email: string; password: string; }, unknown>,
    logout: () => void
}