import React, { useState } from "react";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { DatePicker, Table, Pagination, Modal, Button } from "antd";
import { useMembershipActions } from "../useMembershipsActions";
import { useQuery } from "@tanstack/react-query";
import { getAllInvoicesForMembership } from "../../../services/user-admin.service";

interface FormValues {
  invoiceNumber: string;
  amount: number;
  description: string;
  invoiceDate: string | null;
  paymentDate: string | null;
}

const Membership: React.FC = () => {
  const location = useLocation();
  const selectedMembership = parseInt(
    location.pathname.split("/")[location.pathname.split("/").length - 1]
  );
  const navigate = useNavigate();
  const { addInvoiceMutation } = useMembershipActions();

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .required("Amount is required")
      .positive("Amount must be positive"),
    description: Yup.string().required("Description is required"),
    invoiceDate: Yup.date().required("Invoice date is required"),
    paymentDate: Yup.date().required("Payment date is required"),
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);

  // Fetch invoices with useQuery
  const { data, isLoading, isError, refetch } = useQuery(
    ["invoices", selectedMembership, currentPage],
    () => getAllInvoicesForMembership(selectedMembership, currentPage, pageSize),
    { keepPreviousData: true }
  );

  const handleSubmit = (values: FormValues) => {
    const invoiceData = {
      membershipId: selectedMembership,
      amount: values.amount,
      invoiceDate: new Date(values.invoiceDate || ""),
      paymentDate: new Date(values.paymentDate || ""),
      description: values.description,
    };

    addInvoiceMutation.mutate(invoiceData, {
      onSuccess: () => {
        refetch();
        setIsModalVisible(false); // Close modal on success
      },
      onError: (error: any) => {
        console.error("Error adding invoice:", error);
      },
    });
  };

  const columns = [
    { title: "Invoice Number", dataIndex: "invoiceNumber", key: "invoiceNumber" },
    { title: "Amount", dataIndex: "amount", key: "amount" },
    { title: "Description", dataIndex: "description", key: "description" },
    { title: "Invoice Date", dataIndex: "invoiceDate", key: "invoiceDate" },
    { title: "Payment Date", dataIndex: "paymentDate", key: "paymentDate" },
  ];

  return (
    <>
      <Button type="primary" onClick={() => setIsModalVisible(true)} className="mb-4">
        Add Invoice
      </Button>

      <Modal
        title="Add Invoice"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Formik
          initialValues={{
            invoiceNumber: "",
            amount: 1,
            description: "",
            invoiceDate: null,
            paymentDate: null,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ handleChange, setFieldValue }) => (
            <Form>
              <div className="mb-4">
                <label htmlFor="amount" className="block mb-1">
                  Amount
                </label>
                <Field
                  name="amount"
                  type="number"
                  className="border p-2 w-full"
                  onChange={handleChange}
                />
                <ErrorMessage name="amount" component="div" className="text-red-500" />
              </div>

              <div className="mb-4">
                <label htmlFor="description" className="block mb-1">
                  Description
                </label>
                <Field
                  name="description"
                  as="textarea"
                  className="border p-2 w-full"
                  rows={4}
                  onChange={handleChange}
                />
                <ErrorMessage name="description" component="div" className="text-red-500" />
              </div>

              <div className="mb-4">
                <label htmlFor="invoiceDate" className="block mb-1">
                  Invoice Date
                </label>
                <Field name="invoiceDate">
                  {({ field, form }: any) => (
                    <DatePicker
                      onChange={(date, dateString) => setFieldValue("invoiceDate", dateString)}
                      className="border p-2 w-full"
                    />
                  )}
                </Field>
                <ErrorMessage name="invoiceDate" component="div" className="text-red-500" />
              </div>

              <div className="mb-4">
                <label htmlFor="paymentDate" className="block mb-1">
                  Payment Date (Optional)
                </label>
                <Field name="paymentDate">
                  {({ field, form }: any) => (
                    <DatePicker
                      onChange={(date, dateString) => setFieldValue("paymentDate", dateString)}
                      className="border p-2 w-full"
                    />
                  )}
                </Field>
                <ErrorMessage name="paymentDate" component="div" className="text-red-500" />
              </div>

              <div className="mt-4 text-right">
                <Button type="default" onClick={() => setIsModalVisible(false)} className="mr-2">
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>

      <div className="mt-8">
        <h2 className="text-lg font-semibold mb-4">Invoices</h2>
        <Table
          columns={columns}
          dataSource={data?.data}
          loading={isLoading}
          rowKey="invoiceNumber"
          pagination={false}
        />
        <Pagination
          className="mt-4"
          current={currentPage}
          pageSize={pageSize}
          total={data?.total}
          onChange={(page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
};

export default Membership;
