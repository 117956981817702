import React from "react";
import { UserInfo } from "../@types";

interface ExtraSectionProps {
  title: string;
  filteredUserInfoData: UserInfo;
}

const ExtraSection: React.FC<ExtraSectionProps> = ({
  title,
  filteredUserInfoData,
}) => {

  return (
    <div className="mb-8 p-6 border bg-gray-200 border-gray-300 rounded-lg shadow-md ">
      <p className="text-xl font-semibold mb-4 text-gray-700">{title}</p>
    </div>
  );
};

export default ExtraSection;
