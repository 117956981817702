import { Dispatch, SetStateAction } from "react";
import { IUser } from "../../@types/users";

export default function Header({setUser} : {setUser: Dispatch<SetStateAction<IUser | null>>}) {

    const logout = () => {
        setUser(null);
        localStorage.removeItem('accessToken');
    }

    return (
        <div className="flex w-full items-center p-4 justify-between bg-Grey-navbar">
            <div className="w-1/3">
                <div className="ml-12 flex items-center" >
                    <span className="ml-5 text-blue-900 text-xl">MAINTENAID - Admin Console</span>
                </div>
            </div>
            <div className="cursor-pointer mr-3 hover:underline" onClick={logout}>
                Logout
            </div>
       
        </div>
    )
}