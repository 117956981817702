import { useState } from "react";
import { UserInfo } from "../../@types";

export function useUserState() {
  const [expandedSections, setExpandedSections] = useState({
    details: false,
    maintenance: false,
    extra: false,
    
  });
  const [filteredUserInfoData, setFilteredUserInfoData] =
    useState<UserInfo | null>(null);
  const [user, setUser] = useState<UserInfo | null>(null);
  const [showUserInfo, setShowUserInfo] = useState<boolean>(false);

  return {
    user,
    showUserInfo,
    filteredUserInfoData,
    setFilteredUserInfoData,
    setUser,
    expandedSections,
    setExpandedSections,
  };
}
