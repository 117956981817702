import React, { ReactNode } from 'react';

interface ExpandableSectionProps {
  title: string;
  expanded: boolean;
  onToggle: () => void;
  children: ReactNode; // Explicitly define children prop
}

const ExpandableSection: React.FC<ExpandableSectionProps> = ({ title, expanded, onToggle, children }) => {
  return (
    <div className="section-container mb-4">
      <h3
        className={`section-header cursor-pointer flex items-center p-2 rounded-md ${
          expanded ? "bg-gray-700 text-white" : "bg-gray-400"
        }`}
        onClick={onToggle}
      >
        <span className="flex-grow pr-4 pl-4">{title}</span>
        <span className="ml-auto pr-3">{expanded ? "▲" : "▼"}</span>
      </h3>
      {expanded && (
        <div className="p-4 bg-gray-100">
          {children}
        </div>
      )}
    </div>
  );
};

export default ExpandableSection;
