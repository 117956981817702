import { useEffect, useState } from "react";
import { UserData } from "../../@types";
import { ITEMS_PER_PAGE } from "../../constants";

export function useUsersState() {

  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [users, setUsers] = useState<UserData[]>([]);
  const [totalCount, setTotalCount] = useState(0); // Total number of users

 
  const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);



  return {
  
    users,
    setUsers,
    searchQuery,
    setSearchQuery,
    totalCount,
    setTotalCount,
    currentPage,
    setCurrentPage,
    pageNumbers,
    totalPages,
  };
}
