import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { downloadReport } from '../services/user-admin.service';

export function useMaintenanceDownloader() {
  const [downloading, setDownloading] = useState(false);

  const downloadPDF = (pdfData: string) => {
    setDownloading(true);

    try {
      // Decode the Base64 string
      const byteCharacters = atob(pdfData);
      const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteNumbers);

      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = `report_${new Date().getTime()}.pdf`; 
      a.click();


      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Failed to download PDF:', error);
    } finally {
      setDownloading(false);
    }
  };

  const mutation = useMutation({
    mutationFn: downloadReport,
    onSuccess: async (data: any) => {
      if (data && data.pdfBase64) {
        downloadPDF(data.pdfBase64);
      }
    },
    onError: (error: any) => {
      console.error('Download error:', error);
      alert('Failed to fetch the PDF');
    },
  });

  const downloadMaintenanceReport = mutation.mutate;

  return {
    downloading,
    downloadMaintenanceReport,
  };
}
