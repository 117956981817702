import React, { useState } from 'react';
import { Modal, Button, Table, Pagination, Input, Select } from 'antd';
import { useMembershipActions } from './useMembershipsActions';
import { useMembershipState } from './useMembershipsState';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useFetchMemberships } from './useFetchMemberships';
import { useNavigate } from 'react-router-dom';

interface MembershipFormValues {
  slots: number;
  validationPeriod: ValidationPeriod | '';
}
export enum ValidationPeriod {
    ONE_MONTH = '1 MONTH',
    THREE_MONTHS = '3 MONTHS',
    SIX_MONTHS = '6 MONTHS',
    TWELVE_MONTHS = '12 MONTHS',
  }
  
  function Memberships() {
  const { createMembershipMutation } = useMembershipActions();
  const { page, setPage, limit, setLimit, search, setSearch, state, setState } = useMembershipState();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const membershipsQuery = useFetchMemberships(page, limit, state, search);

  // Validation schema for Formik
  const validationSchema = Yup.object().shape({
    slots: Yup.number().required('Slots are required').min(1, 'At least 1 slot'),
    validationPeriod: Yup.string().required('Validation period is required'),
  });

  const handleCreateMembership = (values: MembershipFormValues, { resetForm }: { resetForm: () => void }) => {
    createMembershipMutation.mutate(values);
    resetForm();
    setIsModalOpen(false);
  };

  return (
    <div>
      {/* Search and Filter Controls */}
      <div className="mb-4 flex gap-4">
        <Input.Search
          placeholder="Search memberships"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="w-52"
        />
        <Select
          placeholder="Select status"
          value={state}
          onChange={(value: any) => setState(value)}
          className="w-40"
        >
          <Select.Option value="">All</Select.Option>
          <Select.Option value="UNUSED">Unused</Select.Option>
          <Select.Option value="USED">Used</Select.Option>
          <Select.Option value="ONHOLD">On Hold</Select.Option>
        </Select>
        <Button type="primary" onClick={() => setIsModalOpen(true)}>
          Create Membership
        </Button>
      </div>

      {/* Memberships Table */}
      <Table
        loading={membershipsQuery.isLoading}
        dataSource={membershipsQuery.data?.data || []}
        rowKey="id"
        columns={[
          { title: 'Code', dataIndex: 'code', key: 'code' },
          { title: 'Slots', dataIndex: 'slots', key: 'slots' },
          { title: 'Validation Period', dataIndex: 'validationPeriod', key: 'validationPeriod' },
          { title: 'State', dataIndex: 'state', key: 'state' },
          { title: 'Company', dataIndex: ['company', 'name'], key: 'company' },
        ]}
        pagination={false}
        onRow={(record: any) => ({
            onClick: () => {
              navigate(`/memberships/${record.id}`); 
            }
          })}
      />

      {/* Pagination */}
      <Pagination
        current={page}
        pageSize={limit}
        total={membershipsQuery.data?.total || 0}
        onChange={(newPage: any, newPageSize: any) => {
          setPage(newPage);
          setLimit(newPageSize);
        }}
      />

      {/* Modal for Creating Membership */}
      <Modal
        title="Create Membership"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        {/* Form to create membership using Formik */}
        <Formik
          initialValues={{ code: '', slots: 1, validationPeriod: '' }}
          validationSchema={validationSchema}
          onSubmit={handleCreateMembership}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="slots" className="block mb-1">Slots</label>
                <Field name="slots" type="number" className="w-full border p-2" />
                <ErrorMessage name="slots" component="div" className="text-red-500" />
              </div>
              <div>
                <label htmlFor="validationPeriod" className="block mb-1">Validation Period</label>
                <Field name="validationPeriod" as="select" className="w-full border p-2">
                  <option value="" label="Select validation period" />
                  {Object.values(ValidationPeriod).map((period) => (
                    <option key={period} value={period}>
                      {period}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="validationPeriod" component="div" className="text-red-500" />
              </div>
              <Button type="primary" htmlType="submit" disabled={isSubmitting}>
                Create
              </Button>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default Memberships;
