/* React imports */
import { Dispatch, SetStateAction, useState } from "react";

/* Third party imports */
import { Form, Formik } from "formik";
import * as Yup from 'yup';

/* Local imports */

import { useMutation } from "@tanstack/react-query";
import { login } from "../../../services";
import { IUser } from "../../../@types/users";
import { SIGNIN_ERRORS } from "../../../errors";
import { CustomFieldWithError } from "../../../components/CustomFIeldWithError";
import CustomButton from "../../../components/custom-button/CustomButton";
import { ButtonSize, ButtonVariant } from "../../../components/custom-button/types";

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .required('Email is required')
        .email('Invalid email address')
        .min(1, 'Email is required'),
    password: Yup.string()
        .required('Password is required')
        .min(1, 'Password required')
});


export default function Login({ setUser }: { setUser: Dispatch<SetStateAction<IUser | null>> }) {

    const [invalidPasswordError, setInvalidPasswordError] = useState<string | null>(null);
    const [invalidEmailError, setInvalidEmailError] = useState<string | null>(null);

    const loginMutation = useMutation(login, {
        onSuccess: (data) => {
            setUser(data.data.user);
            try {
                localStorage.setItem('accessToken', data.data.token);
            } catch (error) {
                console.error("Error storing token in local storage:", error);
            }
        }, onError: ({ response }) => {
            if (response.data.message == SIGNIN_ERRORS.INVALID_PASSWORD) {
                setInvalidPasswordError("Invalid password");
            } else if (response.data.message == SIGNIN_ERRORS.UNREGISTERED_EMAIL) {
                setInvalidEmailError("Invalid email");
            }
        }
    });

    return (
        <div className="flex flex-col w-full p-9 items-center">
            <div className="flex-flex-col w-1/3 mt-5">
                <div className="flex flex-col items-center">
                    <div className="mt-4 text-xl text-Yellow-default font-bold">MAINTENAID - Admin console</div>
                </div>
                <div className="mt-20">
                    <Formik

                        initialValues={{ email: '', password: '', keepMeLogged: false }}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            setInvalidEmailError(null);
                            setInvalidPasswordError(null);
                            loginMutation.mutate(values);
                        }}
                    >
                        {({ errors, isValid, touched, setFieldValue, values }) => {
                            const noFieldsEmpty = values['email'].length > 0 && values['password'].length > 0
                            const disabled = !isValid || !noFieldsEmpty;

                            return (
                                <Form className="mt-4">

                                    <div className="w-full">
                                        <label htmlFor="email">{"Email"}*</label>
                                        <div>
                                            <CustomFieldWithError className="w-full h-10 mt-1" type="email" id="email" name="email" error={errors.email || invalidEmailError} touched={!!touched.email} />
                                        </div>
                                    </div>
                                    <div className="w-full mt-4">
                                        <label htmlFor="password">{"Password"}*</label>
                                        <div>
                                            <CustomFieldWithError className="w-full h-10 mt-1" type="password" id="password" name="password" error={errors.password || invalidPasswordError} touched={!!touched.password} />
                                        </div>
                                    </div>

                                    <CustomButton type="submit" className="mt-6 w-full" disabled={disabled} variant={ButtonVariant.PRIMARY} size={ButtonSize.MEDIUM}>
                                        {"Login"}
                                    </CustomButton>

                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </div>
        </div>
    )
}