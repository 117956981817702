import { Form, Formik } from "formik";
import { useUsersState } from "./useUsersState";
import { useUsersActions } from "./useUsersActions";
import TableHeader from "../../components/UserTable";
import { UserData } from "../../@types";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUsersEffects } from "./useUsersEffects";

function Users() {
  const navigate = useNavigate();

  const userHeaders = ["ID", "CreatedAt", "Name", "Email", "Role", "Status"];

  const {
    users,
    setUsers,
    searchQuery,
    setSearchQuery,
    totalCount,
    setTotalCount,
    currentPage,
    setCurrentPage,
    pageNumbers,
    totalPages,
  } = useUsersState();

  const usersState = {
    searchQuery,
    currentPage,
    setUsers,
    setTotalCount,
    setCurrentPage,
  };

  const [sortConfigs, setSortConfigs] = useState<{
    [key: string]: { direction: string };
  }>({});

  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  const { usersData, handlePageChange } = useUsersActions({
    usersState,
    sortBy,
    sortDirection,
  });

  useUsersEffects({
    usersData,
    setUsers,
    setTotalCount,
    currentPage,
    setCurrentPage,
  });

  const handleSort = (columnName: keyof UserData) => {
    const currentConfig = sortConfigs[columnName] || { direction: "asc" };
    const direction = currentConfig.direction === "asc" ? "desc" : "asc";
    const newSortConfig = { [columnName]: { direction } };
    setSortConfigs({ ...sortConfigs, ...newSortConfig });
    setSortBy(columnName);
    setSortDirection(direction);
  };

  const arrowStyle = (columnName: keyof UserData) => ({
    cursor: "pointer",
    display: "inline-block",
    width: "20px",
    height: "20px",
    verticalAlign: "middle",
  });

  const arrowSpanStyle = (columnName: keyof UserData) => ({
    display: "inline-block",
    transition: "transform 0.3s",
    transform:
      sortConfigs[columnName]?.direction === "asc"
        ? "rotate(0deg)"
        : "rotate(180deg)",
  });

  return (
    <>
      <Formik
        initialValues={{
          searchQuery,
        }}
        enableReinitialize={true}
        onSubmit={() => {}}
      >
        {({ values, handleChange, enableReinitialize }) => (
          <Form className="space-y-4 pt-4">
            <div className="flex items-center space-x-8">
              <span className="text-lg font-bold">Search:</span>
              <input
                type="text"
                name="searchQuery"
                value={values.searchQuery}
                onChange={(e) => {
                  handleChange(e);
                  setSearchQuery(e.target.value);
                  setCurrentPage(1);
                }}
                placeholder="Search for a user"
                className="border border-gray-300 px-2 py-1 rounded-md w-96"
              />
            </div>
          </Form>
        )}
      </Formik>
      <div className="my-8 ">
        <table className="border-2 rounded-md bg-white w-full text-left table-auto border-collapse ">
          <TableHeader
            headers={userHeaders}
            handleSort={handleSort}
            arrowStyle={arrowStyle}
            arrowSpanStyle={arrowSpanStyle}
          />

          <tbody>
            {users.map((item: UserData, index: number) => (
              <tr
                key={index}
                className="hover:bg-gray-100 transition-colors duration-200 cursor-pointer"
                onClick={() => {
                  navigate(`/users/${item.id}`);
                }}
              >
                <td className="px-4 py-2 border-l border-b border-r border-gray-500 rounded-md">
                  {item.id}
                </td>
                <td className="px-4 py-2 border-l border-b border-r border-gray-500 rounded-md">
                  {new Date(item.createdAt).toLocaleDateString()}
                </td>

                <td className="px-4 py-2 border-l border-b border-r border-gray-500 rounded-md">
                  {item.name}
                </td>
                <td className="px-4 py-2 border-b border-r border-gray-500 rounded-md">
                  {item.email}
                </td>
                <td className="px-4 py-2 border-b border-r border-gray-500 rounded-md">
                  {item.role}
                </td>
                <td
                  className={`px-4 py-2 border-b border-r border-gray-500 ${
                    item?.userStatus?.status === "online"
                      ? "bg-green-400"
                      : "bg-red-400"
                  }`}
                >
                  {item?.userStatus?.status || "offline"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-end space-x-2 mt-4">
          <button
            className="bg-white border border-black text-black px-2 py-1 rounded-md hover:bg-gray-100 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            {"<"}
          </button>
          {pageNumbers.map((number: number) => (
            <button
              key={number}
              onClick={() => handlePageChange(number)}
              disabled={number === currentPage}
              className={`bg-white border border-black text-black px-2 py-1 rounded-md hover:bg-gray-100 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm ${
                number === currentPage ? "opacity-50 cursor-default" : ""
              }`}
            >
              {number}
            </button>
          ))}
          <button
            className="bg-white border border-black text-black px-2 py-1 rounded-md hover:bg-gray-100 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            {">"}
          </button>
        </div>
      </div>
    </>
  );
}

export default Users;
