import React, { useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  getVersion,
  updatePatchNotes,
} from "../../services/user-admin.service";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";

// Define a TypeScript interface for patch notes
interface PatchNotes {
  bugs?: string[];
  features?: string[];
}

// Validation schema for Formik
const validationSchema = Yup.object({
  bugs: Yup.array()
    .of(Yup.string().min(1, "Bug description cannot be empty"))
    .test(
      "non-empty-or-feature-present",
      "At least one bug description is required if features are not provided",
      function (value = []) {
        const filteredBugs = value.filter((bug) => bug && bug.trim() !== "");
        const { features } = this.parent;
        const filteredFeatures = features.filter(
          (feature: any) => feature && feature.trim() !== ""
        );
        return filteredBugs.length > 0 || filteredFeatures.length > 0;
      }
    ),
  features: Yup.array()
    .of(Yup.string().min(1, "Feature description cannot be empty"))
    .test(
      "non-empty-or-bug-present",
      "At least one feature description is required if bugs are not provided",
      function (value = []) {
        const filteredFeatures = value.filter(
          (feature) => feature && feature.trim() !== ""
        );
        const { bugs } = this.parent;
        const filteredBugs = bugs.filter(
          (bug: any) => bug && bug.trim() !== ""
        );
        return filteredFeatures.length > 0 || filteredBugs.length > 0;
      }
    ),
});

function Version() {
  const {
    data: versionData,
    isLoading,
    error,
    refetch,
  } = useQuery(["versionData"], getVersion);
  const [showAllVersions, setShowAllVersions] = useState(false);
  const mutation = useMutation({
    mutationFn: ({
      versionId,
      patchNotes,
    }: {
      versionId: number;
      patchNotes: PatchNotes;
    }) => {
      return updatePatchNotes(versionId, patchNotes);
    },
    onSuccess: () => {
      refetch();
    },
    onError: (error: any) => {
      console.error("Error updating patch notes:", error);
    },
  });

  if (isLoading) {
    return <div className="p-4">Loading...</div>;
  }

  if (error || !versionData) {
    return <div className="p-4">Error loading data...</div>;
  }
  const handleToggleVersions = () => {
    setShowAllVersions((prev) => !prev);
  };

  
  // Get the latest version
  const latestVersion = versionData?.[0];
  const patchNotes = latestVersion?.patchNotes || { bugs: [], features: [] };
  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-6">MAINTENAID NATIVE VERSION</h1>

      {latestVersion ? (
        <div>
          {/* Form for adding or updating patch notes */}
          <div
            className={`mb-6 p-4 rounded shadow-sm border ${
              patchNotes.bugs.length < 1 && patchNotes.features.length < 1
                ? "border-red-500"
                : "border-green-800"
            }`}
          >
            <h2 className="text-lg font-semibold mb-4 text-blue-800">
              Update Patch Notes for latest Version {latestVersion.version}  ({new Date(latestVersion.createdAt).toLocaleDateString()})
            </h2>
            <Formik
              initialValues={{
                bugs: latestVersion?.patchNotes?.bugs || [],
                features: latestVersion?.patchNotes?.features || [],
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                const updatedPatchNotes: PatchNotes = {
                  bugs: values.bugs.filter(Boolean),
                  features: values.features.filter(Boolean),
                };

                mutation.mutate({
                  versionId: latestVersion.id,
                  patchNotes: updatedPatchNotes,
                });
              }}
            >
              {({ isSubmitting, values }) => (
                <Form>
                  <div className="mb-4">
                    <label className="block font-semibold mb-2">Bugs:</label>
                    <FieldArray name="bugs">
                      {({ push, remove, form }) => (
                        <div>
                          {form.values.bugs.length > 0 &&
                            form.values.bugs.map((_: any, index: any) => (
                              <div
                                key={index}
                                className="flex items-center mb-2"
                              >
                                <Field
                                  name={`bugs[${index}]`}
                                  placeholder="Add a bug"
                                  className="block w-full p-2 border border-gray-300 rounded mr-2"
                                />

                                <button
                                  type="button"
                                  onClick={() => remove(index)}
                                  className="text-red-500"
                                >
                                  Remove
                                </button>
                              </div>
                            ))}
                          <button
                            type="button"
                            onClick={() => push("")}
                            className="text-blue-500"
                          >
                            Add Another Bug
                          </button>
                        </div>
                      )}
                    </FieldArray>
                    <ErrorMessage
                      name="bugs"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block font-semibold mb-2">
                      Features:
                    </label>
                    <FieldArray name="features">
                      {({ push, remove, form }) => (
                        <div>
                          {form.values.features.length > 0 &&
                            form.values.features.map((_: any, index: any) => (
                              <div
                                key={index}
                                className="flex items-center mb-2"
                              >
                                <Field
                                  name={`features[${index}]`}
                                  placeholder="Add a feature"
                                  className="block w-full p-2 border border-gray-300 rounded mr-2"
                                />

                                <button
                                  type="button"
                                  onClick={() => remove(index)}
                                  className="text-red-500"
                                >
                                  Remove
                                </button>
                              </div>
                            ))}
                          <button
                            type="button"
                            onClick={() => push("")}
                            className="text-blue-500"
                          >
                            Add Another Feature
                          </button>
                        </div>
                      )}
                    </FieldArray>
                    <ErrorMessage
                      name="features"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <button
                    type="submit"
                    className={`px-4 py-2 rounded text-white ${
                      mutation.isLoading ||
                      (values.bugs.length < 1 && values.features.length < 1) ||
                      (JSON.stringify(values.bugs) ===
                        JSON.stringify(latestVersion?.patchNotes?.bugs) &&
                        JSON.stringify(values.features) ===
                          JSON.stringify(latestVersion?.patchNotes?.features))
                        ? "bg-gray-400 cursor-not-allowed" // Disabled color
                        : "bg-green-800" // Enabled color
                    }`}
                    disabled={
                      mutation.isLoading ||
                      (values.bugs.length < 1 && values.features.length < 1) ||
                      (JSON.stringify(values.bugs) ===
                        JSON.stringify(latestVersion?.patchNotes?.bugs) &&
                        JSON.stringify(values.features) ===
                          JSON.stringify(latestVersion?.patchNotes?.features))
                    }
                  >
                    {mutation.isLoading ? "Adding..." : "Add Patch Notes"}
                  </button>

                  {mutation.error && (
                    <p className="text-red-500 mt-2">
                      Error adding patch notes
                    </p>
                  )}
                  {mutation.isSuccess && (
                    <p className="text-green-500 mt-2">
                      Patch notes added successfully!
                    </p>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <div className="p-4">No versions available.</div>
      )}

      {/* Toggle Button */}
      <button
        type="button"
        onClick={handleToggleVersions}
        className="mb-4 px-4 py-2 rounded text-white bg-gray-600 hover:bg-blue-500"
      >
        {showAllVersions ? "Hide All Versions" : "Show All Versions"}
      </button>
      {showAllVersions && (
        <div className="space-y-4">
          {versionData.map((version: any) => (
            <div
              key={version.id}
              className="p-4 border border-gray-600 rounded shadow-sm"
            >
              <h3 className="text-lg font-semibold mb-2 text-blue-700 ">
                Version {version.version} ({new Date(version.createdAt).toLocaleDateString()})
              </h3>
              <div className="ml-4">
                <strong>Bugs:</strong>
                <ul className="list-disc list-inside pl-4">
                  {version.patchNotes?.bugs?.length ? (
                    version.patchNotes.bugs.map(
                      (bug: string, index: number) => <li key={index}>{bug}</li>
                    )
                  ) : (
                    <li>No bugs reported</li>
                  )}
                </ul>
              </div>
              <div className="ml-4">
                <strong>Features:</strong>
                <ul className="list-disc list-inside pl-4">
                  {version.patchNotes?.features?.length ? (
                    version.patchNotes.features.map(
                      (feature: string, index: number) => (
                        <li key={index}>{feature}</li>
                      )
                    )
                  ) : (
                    <li>No features reported</li>
                  )}
                </ul>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Version;
