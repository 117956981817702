/* React imports */
import { createContext, useContext } from 'react';
import { IUser } from '../@types/users';
import { goToWindow } from '../services/utils';


/* Local imports */

export const AuthContext = createContext<{user: IUser | null, setUser: any}>({user: null, setUser: null});

export const useAuth = () => {
  const {user, setUser} = useContext(AuthContext);

  const logoutUser = () => {
    logoutUser();
    setUser(null);
    goToWindow('/');
    localStorage.removeItem('accessToken');
  }

  return {
    loggedUser: user,
    setUser,
    logoutUser
  }
};
