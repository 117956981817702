import { axiosInstance } from ".";
import { UserInfo } from "../@types";
import { Role } from "../@types/users";

export const authenticate = async (data: {
  email: string;
  password: string;
}) => {
  const response = await axiosInstance.post(
    "/auth/login",
    {},
    { auth: { username: data.email, password: data.password } }
  );
  return response.data;
};

export const authenticateUserByToken = async () => {
  return (
    await axiosInstance.get("/users/current", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access-token")}`,
      },
    })
  ).data;
};

export async function getUsersInfo(
  searchQuery: string,
  currentPage: number,
  itemsPerPage: number,
  role: string,
  userId: number,
  sortBy?: string,
  sortDirection?: string
) {
  try {
    const response = await axiosInstance.get("/user-admin/info", {
      params: {
        searchQuery,
        currentPage: currentPage.toString(),
        itemsPerPage: itemsPerPage.toString(),
        role,
        userId,
        sortBy,
        sortDirection,
      },
    });

    const totalCount = response.data.totalCount;
    console.log("🚀 ~ response.data.users:", response.data.users);

    return { users: response.data.users, totalCount };
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
}

export async function getUserInfo(id: number): Promise<{ userInfo: UserInfo }> {
  try {
    const response = await axiosInstance.get(`/user-admin/user/info`, {
      params: {
        id,
      },
    });

    return { userInfo: response.data.user };
  } catch (error) {
    console.error("Error fetching user information:", error);
    throw error;
  }
}

export async function getMaintenanceList(
  id: number,
  page: number,
  limit: number,
  sortBy: string,
  sortOrder: "ASC" | "DESC",
  search: string,
  status: string
): Promise<{ data: any; total: number }> {
  try {
    const response = await axiosInstance.get("/user-admin/maintenance", {
      params: { id, page, limit, sortBy, sortOrder, search, status },
    });
    return {
      data: response.data.data,
      total: response.data.total,
    };
  } catch (error) {
    console.error("Error get form list:", error);
    throw error;
  }
}

export const getTickets = async (
  filters: {
    status?: string;
    priority?: string;
    type?: string;
    page?: number;
  },
  sortBy: string,
  sortOrder: "asc" | "desc"
) => {
  try {
    const response = await axiosInstance.get("/user-admin/tickets", {
      params: {
        ...filters,
        sortBy,
        sortOrder,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching tickets:", error);
    throw error;
  }
};

export async function getTicketInfo(ticketId: number) {
  try {
    const response = await axiosInstance.get("/user-admin/ticket", {
      params: {
        ticketId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching ticket:", error);
    throw error;
  }
}

export async function submitNewMessage({
  ticketId,
  message,
}: {
  ticketId: number;
  message: string;
}) {
  try {
    const response = await axiosInstance.post("/user-admin/ticket/message", {
      ticketId,
      message,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching ticket:", error);
    throw error;
  }
}

export const fetchAdmins = async () => {
  const response = await axiosInstance.get(`/user-admin/admins`);
  return response.data;
};

export const updateTicket = async (
  id: number,
  updateData: { status?: string; priority?: string; type?: string }
) => {
  const response = await axiosInstance.patch(
    `/user-admin/ticket/${id}`,
    updateData
  );
  return response.data;
};

export const assignUser = async (ticketId: number, userId: number) => {
  const response = await axiosInstance.patch(
    `/user-admin/ticket/assign/${ticketId}`,
    { userId }
  );
  return response.data;
};

export function updateUserRole(userId: number, role: Role) {
  return axiosInstance.patch("/user-admin/role", {
    userId: userId,
    role: role,
  });
}

export const getStatistics = async () => {
  try {
    const response = await axiosInstance.get("/user-admin/statistics");
    return response.data;
  } catch (error) {
    console.error("Error fetching statistics:", error);
    throw error;
  }
};

export const getVersion = async () => {
  try {
    const response = await axiosInstance.get("/user-admin/version");
    return response.data;
  } catch (error) {
    console.error("Error fetching version:", error);
    throw error;
  }
};


export const updatePatchNotes = async (versionId: number, patchNotes: { bugs?: string[], features?: string[] }) => {
  try {
    const response = await axiosInstance.patch(`/user-admin/patch-notes/${versionId}`, {patchNotes});
    return response.data;
  } catch (error) {
    console.error("Error updating versio patch notes:", error);
    throw error;
  }
};

export async function downloadReport(id: number): Promise<any> {
  try {
    return (await axiosInstance.post(`/user-admin/${id}/report`)).data;
  } catch (error) {
    console.error("Error get form list:", error);
    throw error;
  }
}

export const fetchAttachmentImage = async (
  attachmentId: number,
): Promise<string | null> => {
  try {
    const response = await axiosInstance.get(
      `/user-admin/ticket/anexos/${attachmentId}`,
      {
        responseType: 'blob',
      },
    );
    const blob = response.data;
    const reader = new FileReader();
    return new Promise<string | null>((resolve, reject) => {
      reader.onloadend = () => {
        const base64data = reader.result as string;
        resolve(base64data);
      };
      reader.onerror = () => reject('Failed to read Blob');
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error('Error fetching attachment:', error);
    return null;
  }
};

export const getAllMemberships = async (
  page: number,
  limit: number,
  state?: string,
  search?: string
): Promise<{ data: any; page: number; limit: number; total: number }> => {
  try {
    const response = await axiosInstance.get("/memberships", {
      params: {
        page,
        limit,
        state,
        search,
      },
    });

    return {
      data: response.data.data,
      page: response.data.page,
      limit: response.data.limit,
      total: response.data.total,
    };
  } catch (error) {
    console.error("Error fetching memberships:", error);
    throw error;
  }
};

export const createMembership = async (membershipData: {
  slots: number;
  validationPeriod: string; 
}) => {
  try {
    const response = await axiosInstance.post("/memberships", membershipData);
    return response.data;
  } catch (error) {
    console.error("Error creating membership:", error);
    throw error;
  }
};

export const addInvoiceToMembership = async (invoiceData: {
  membershipId: number;
  amount: number;
  invoiceDate: Date; 
  paymentDate: Date;
  description?: string
}) => {
  try {
    const response = await axiosInstance.post("/memberships/add-invoice", invoiceData);
    return response.data;
  } catch (error) {
    console.error("Error adding invoice to membership:", error);
    throw error;
  }
};


export const getAllInvoicesForMembership = async (
  membershipId: number,
  page: number = 1,
  limit: number = 10
): Promise<{ data: any; page: number; limit: number; total: number }> => {
  try {
    const response = await axiosInstance.get(`/memberships/${membershipId}/invoices`, {
      params: {
        page,
        limit,
      },
    });

    return {
      data: response.data.data,
      page: response.data.page,
      limit: response.data.limit,
      total: response.data.total,
    };
  } catch (error) {
    console.error("Error fetching invoices for membership:", error);
    throw error;
  }
};