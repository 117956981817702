import { useMutation, useQuery } from "@tanstack/react-query";
import { Field, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import {
  fetchAttachmentImage,
  getTicketInfo,
  submitNewMessage,
} from "../../../services/user-admin.service";

// Modal component to preview large images
const ImagePreviewModal = ({
  imageUrl,
  onClose,
}: {
  imageUrl: string;
  onClose: () => void;
}) => {
  if (!imageUrl) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
      <div className="relative bg-white p-4 rounded-lg h-[90%]">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 bg-gray-500 text-white rounded-full p-2"
        >
          ×
        </button>
        <img src={imageUrl} alt="Preview" className="max-w-[90%] max-h-[90%]" />
      </div>
    </div>
  );
};

function TicketDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const messagesEndRef = useRef<HTMLDivElement>(null); // Ref to scroll to the bottom

  const [attachments, setAttachments] = useState<{
    [key: number]: string | null;
  }>({});
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const selectedTicket = parseInt(
    location.pathname.split("/")[location.pathname.split("/").length - 1]
  );

  const {
    data: ticketDetails,
    refetch,
    isLoading,
  } = useQuery(
    ["ticketDetails", selectedTicket],
    () => {
      if (selectedTicket) {
        return getTicketInfo(selectedTicket);
      } else {
        return Promise.resolve(null);
      }
    },
    {
      enabled: !!selectedTicket,
      keepPreviousData: true,
    }
  );

  const handleSubmitMessage = (message: string) => {
    if (selectedTicket && message.trim()) {
      handleSubmitNewMessage.mutate({ ticketId: selectedTicket, message });
    }
  };

  const handleSubmitNewMessage = useMutation(
    async (data: { ticketId: number; message: string }) => {
      return submitNewMessage({
        ticketId: data.ticketId,
        message: data.message,
      });
    },
    {
      onSuccess: () => {
        refetch();
      },
      onError: (error) => {
        console.error("failed to add New message:", error);
      },
    }
  );

  // Scroll to bottom on new messages
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [ticketDetails?.ticket?.chat?.messages]);

  useEffect(() => {
    const fetchAttachments = async () => {
      if (ticketDetails?.ticket?.chat?.messages) {
        const newAttachments: { [key: number]: string | null } = {};
        for (const message of ticketDetails.ticket.chat.messages) {
          if (message.attachments) {
            for (const attachment of message.attachments) {
              if (!attachments[attachment.id]) {
                const imageUrl = await fetchAttachmentImage(attachment.id);
                newAttachments[attachment.id] = imageUrl;
              }
            }
          }
        }
        setAttachments((prev) => ({ ...prev, ...newAttachments }));
      }
    };

    fetchAttachments();
  }, [ticketDetails?.ticket?.chat?.messages]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const sortedMessages = ticketDetails?.ticket?.chat?.messages
    ?.slice()
    .sort(
      (a: any, b: any) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );

  return (
    <>
      <div className="mb-4">
        <button
          onClick={() => navigate("/customer-support")}
          className="bg-white border border-black text-black py-1 px-2 rounded-md"
        >
          Back
        </button>
      </div>
      <div className="text-[20px] text-blue-800 font-bold mb-4">
        {ticketDetails.ticket.title}
      </div>
      <div className="flex flex-col h-full">
        <div className="flex-1 overflow-y-auto p-4 bg-gray-100 relative">
          {sortedMessages?.map((message: any) => {
            const isUserMessage =
              ticketDetails.ticket.user.id === message.user?.id;
            const bubbleStyles = isUserMessage
              ? "bg-gray-300 text-black mr-auto rounded-lg"
              : "bg-blue-500 text-white ml-auto rounded-lg";

            const messageDate = new Date(message.createdAt);
            const formattedDate = messageDate.toLocaleDateString();
            const formattedTime = messageDate.toLocaleTimeString();

            return (
              <div
                key={message.id}
                className={`mb-2 p-2 max-w-[80%] ${bubbleStyles} flex flex-col`}
                style={{ alignSelf: isUserMessage ? "flex-start" : "flex-end" }}
              >
                <div>{message.message}</div>
                {message.attachments && message.attachments.length > 0 && (
                  <div className="flex flex-wrap gap-2 mt-2">
                    {message.attachments.map((attachment: any) =>
                      attachments[attachment.id] ? (
                        <img
                          key={attachment.id}
                          src={attachments[attachment.id] || ""}
                          alt={`Attachment ${attachment.id}`}
                          className="w-16 h-16 object-cover rounded-md cursor-pointer"
                          onClick={() =>
                            setSelectedImage(attachments[attachment.id] || "")
                          }
                        />
                      ) : (
                        <div
                          key={attachment.id}
                          className="w-16 h-16 bg-gray-300 rounded-md"
                        ></div>
                      )
                    )}
                  </div>
                )}
                <div className="text-xs text-gray-500 mt-1">
                  {formattedDate} {formattedTime}
                </div>
              </div>
            );
          })}
          <div ref={messagesEndRef} />
        </div>

        <Formik
          initialValues={{ message: "" }}
          onSubmit={(values, { resetForm }) => {
            handleSubmitMessage(values.message);
            resetForm();
          }}
        >
          {({ handleChange, handleSubmit, values }) => (
            <form
              onSubmit={handleSubmit}
              className="bg-gray-200 p-4 border-t border-gray-300"
            >
              <div className="flex">
                <Field
                  name="message"
                  type="text"
                  placeholder="Type your message..."
                  className="flex-1 p-2 border rounded-md"
                  onChange={handleChange}
                  value={values.message}
                />
                <button
                  type="submit"
                  className="bg-green-800 text-white ml-2 p-2 rounded-md"
                >
                  Send
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>

      {/* Image Preview Modal */}
      {selectedImage && (
        <ImagePreviewModal
          imageUrl={selectedImage}
          onClose={() => setSelectedImage(null)}
        />
      )}
    </>
  );
}

export default TicketDetails;
