import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { getUserInfo } from "../../services/user-admin.service";

export function useUserActions({
  selectedUser,
  user,
  setUser,
  setExpandedSections,
  setFilteredUserInfoData,

}: {
  selectedUser: number;
  user: any;
  setUser: any;
  setExpandedSections: any;
  setFilteredUserInfoData: any;
}) {
  const navigate = useNavigate();

  const { data: userInfo, refetch } = useQuery(
    ["userDetails", selectedUser],
    () => {
      if (selectedUser) {
        return getUserInfo(selectedUser);
      } else {
        return Promise.resolve(null);
      }
    },
    {
      enabled: !!selectedUser,
      keepPreviousData: true,
      onSuccess: (data) => {
        setUser(data?.userInfo || null);
        setFilteredUserInfoData(data?.userInfo || null);

      },
    }
  );

  const handleToggleSection = (section: any) => {
    setExpandedSections((prev: any) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  return {
    userInfo,
    refetch,
    handleToggleSection,
  };
}
