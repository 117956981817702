import { useEffect, useState } from "react";
import { WrapperGuardInput } from "../@types";



export const WrapperGuard = ({children, guardView, dependencies, accessCondition} : WrapperGuardInput) : JSX.Element => {

    const [hasAccess, setHasAccess] = useState<boolean>(false);

    useEffect(()=> {
        if(accessCondition) {
            setHasAccess(true);
        } else {
            setHasAccess(false);
        }
    },[...dependencies])

    return (hasAccess ? <>{children}</> :  <>{guardView}</>)

}
