import React, { useState } from "react";
import { UserDetails, UserInfo } from "../@types";
import { Role } from "../@types/users";
import { useMutation } from "@tanstack/react-query";
import { updateUserRole } from "../services/user-admin.service";
import UserRolePopup from "../pages/User/components/userRolePopUp";

interface DetailsSectionProps {
  filteredUserInfoData: UserDetails;
}

export function DetailsSection({
  userData,
  refetch,
}: {
  userData: any;
  refetch: any;
}) {
  const [showUserRolePopup, setShowUserRolePopup] = useState(false);

  const handleUserRolePopupConfirm = (role: Role) => {
    setShowUserRolePopup(false);
    console.log("here")
    if (userData.userId) {
      console.log("here2")

      handleUpdateUserRole.mutate({ userId: userData.userId, role });
    }
  };

  const handleUpdateUserRole = useMutation(
    async (data: { userId: number; role: Role }) => {
      return updateUserRole(data.userId, data.role);
    },
    {
      onSuccess: (data) => {
        console.log("Role update successful:", data);
        refetch();  // Re-fetch user data to get the updated role
      },
      onError: (error) => {
        console.error("Role update failed:", error);
        // Handle the error, show a message to the user if necessary
      },
    }
  );
  

  return (
    <>
      <div className=" p-6 border bg-gray-200 border-gray-300 rounded-lg shadow-md ">
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
          {/* First Column */}
          <div className="mb-8">
            <p className="text-lg font-bold ">First Name:</p>
            <p className="text-gray-700 mb-3">
              {userData?.userDetails?.firstName || "N/A"}
            </p>

            <p className="text-lg font-bold ">Last Name:</p>
            <p className="text-gray-700 mb-3">
              {userData?.userDetails?.lastName || "N/A"}
            </p>

            <p className="text-lg font-bold ">NIF:</p>
            <p className="text-gray-700 mb-3">
              {userData?.userDetails?.nif || "N/A"}
            </p>

            <p className="text-lg font-bold ">Role:</p>
            <p className="text-gray-700 mb-3">{userData?.role || "N/A"}</p>
          </div>

          {/* Second Column */}
          <div className="mb-8">
            <p className="text-lg font-bold ">Website:</p>
            <p className="text-gray-700 mb-3">
              {userData?.userDetails?.website || "N/A"}
            </p>

            <p className="text-lg font-bold ">Mobile Number:</p>
            <p className="text-gray-700 mb-3">
              {userData?.userDetails?.mobileNumber || "N/A"}
            </p>

            <p className="text-lg font-bold ">Phone Number:</p>
            <p className="text-gray-700 mb-3">
              {userData?.userDetails?.phoneNumber || "N/A"}
            </p>
          </div>

          {/* Third Column */}
          <div className="mb-8">
            <p className="text-lg font-bold ">Address:</p>
            <p className="text-gray-700 mb-3">
              {userData?.userDetails?.address || "N/A"}
            </p>

            <p className="text-lg font-bold ">PC3:</p>
            <p className="text-gray-700 mb-3">
              {userData?.userDetails?.pc3 || "N/A"}
            </p>

            <p className="text-lg font-bold ">PC4:</p>
            <p className="text-gray-700 mb-3">
              {userData?.userDetails?.pc4 || "N/A"}
            </p>

            <p className="text-lg font-bold ">City:</p>
            <p className="text-gray-700 mb-3">
              {userData?.userDetails?.city || "N/A"}
            </p>
          </div>
          {/* Fourth Column */}
          <div className="mb-8">
            <p className="text-lg font-bold ">Activity:</p>
            <p className="text-gray-700 mb-3">
              {userData?.userDetails?.activity || "N/A"}
            </p>

            <p className="text-lg font-bold ">CAE:</p>
            <p className="text-gray-700 mb-3">{userData?.userDetails?.cae}</p>

            <p className="text-lg font-bold ">Coordinates:</p>
            <p className="text-gray-700 mb-3">
              {userData?.userDetails?.coordinates || "N/A"}
            </p>
          </div>

          <div className="mb-8">
            <p className="text-lg font-bold ">Actions:</p>
            <button
              type="button"
              onClick={() => setShowUserRolePopup(true)}
              className="bg-gray-500 border border-black text-white py-1 px-2 mb-4 mr-4 rounded-md hover:bg-blue-500"
            >
              Update Role to Admin (Only)
            </button>
          </div>
        </div>
        {
        <UserRolePopup
          isOpen={showUserRolePopup}
          onClose={() => setShowUserRolePopup(false)}
          onConfirm={handleUserRolePopupConfirm}
        />
      }
      </div>
    </>
  );
}
