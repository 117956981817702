import { Dispatch, SetStateAction, useEffect } from "react";
import { ITEMS_PER_PAGE } from "../../constants";

export function useUsersEffects({
  usersData,
  setUsers,
  setTotalCount,
  currentPage,
  setCurrentPage,
}: {
  usersData: any;
  setUsers: (v: any) => void;
  setTotalCount: Dispatch<SetStateAction<number>>;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}) {
  useEffect(() => {
    if (usersData && usersData.users) {
      setUsers(usersData.users);
      setTotalCount(usersData.totalCount);

      const totalPages = Math.ceil(usersData.totalCount / ITEMS_PER_PAGE);
      if (currentPage > totalPages) {
        setCurrentPage(1);
      }
    }
  }, [usersData, currentPage, ITEMS_PER_PAGE]);
}
