import { useState } from 'react';

export const useMembershipState = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState('');
  const [state, setState] = useState('');

  return {
    page,
    setPage,
    limit,
    setLimit,
    search,
    setSearch,
    state,
    setState,
  };
};
