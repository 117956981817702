/* React imports */
import { useEffect, useState } from "react";

/* Third party imports */
import { InternalAxiosRequestConfig } from "axios";

/* Local imports */
import { axiosInstance } from "../services";
import { IUser } from "../@types/users";


export const useInterceptRequest = (user: IUser | null) => {
    const [requestInterceptor, setRequestInterceptor] = useState<any>(null);

    useEffect(() => {
        if (user) {
            const token = localStorage.getItem('accessToken');
            const interceptorId = axiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
                config.headers['Authorization'] = `Bearer ${token}`;
                return config;
            },
                (error) => {
                    console.log("Error adding token!");
                    return Promise.reject(error);
                }
            )
            setRequestInterceptor(interceptorId);
        } else {
            axiosInstance.interceptors.request.eject(requestInterceptor);
        }
    }, [user])
}

export const useInterceptorResponse = (user: IUser | null, logoutUser: any) => {
    const [responseInterceptor, setResponseInterceptor] = useState<any>(null);

    useEffect(() => {
        if(user) {
            const interceptorId = axiosInstance.interceptors.response.use(
                (response) => response,
                (error) => {
                    if (error.response.status === 401) {
                        logoutUser();
                    }
                    return Promise.reject(error);
                }
            )
            setResponseInterceptor(interceptorId);
        } else {    
            axiosInstance.interceptors.response.eject(responseInterceptor);
        }
   
    }, [user])

}