import React from "react";

type TableHeaderProps = {
  headers: string[];
  handleSort?(header: string): void;
  arrowStyle?(header: string): React.CSSProperties;
  arrowSpanStyle?(header: string): React.CSSProperties;
};



const TableHeader: React.FC<TableHeaderProps> = ({
  headers,
  handleSort,
  arrowStyle,
  arrowSpanStyle,
}) => {
  let headerToFieldMapping: Record<string, string>;

if (handleSort && arrowStyle && arrowSpanStyle) {
  headerToFieldMapping = {
    ID: "id",
    CreatedAt: "createdAt",
    Name: "name",
    Email: "email",
    Role: "role",
    Status: "status"
  };

}

  return (
    <thead className="bg-Yellow-default border-2 border-black rounded-xl">
      <tr>
        {headers.map((header, index) => (
          <th
            key={index}
            className={`px-2 py-2 border border-black text-center`}
          >
            <span className="px-2">{header}</span>
            {handleSort && arrowStyle && arrowSpanStyle && (
              <div
                onClick={() => handleSort(headerToFieldMapping[header])}
                style={arrowStyle(headerToFieldMapping[header])}
                className="arrow"
              >
                {/* Arrow icon with rotation based on sorting direction */}
                <span style={arrowSpanStyle(headerToFieldMapping[header])} className="arrow-span">
                  &#9660;
                </span>
              </div>
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
