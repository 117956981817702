import { ReactElement } from "react";
import { Role } from "./users";


export type IRoute = {
  name?: string;
  path: string;
  component: ReactElement;
  showOnNavbar?: boolean;
  roles: Role[];
};

export interface WrapperGuardInput {
  children: JSX.Element | JSX.Element[];
  guardView: JSX.Element;
  dependencies: any[];
  accessCondition: boolean;
}

export type UserData = {
  id: number;
  createdAt: string;
  name: string;
  email: string;
  role: string;
  userStatus?: {status: string};
};

export type UserInfo = {
  userId: number;
  name?: string;
  email?: string;
  role?: string;
  userDetails?: UserDetails;
  maintenance?: Maintenance[];
  onboarded?: boolean;
  emailVerified?: boolean;
  nextNumber?: number;
  seriesPrefix?: string;
  fcmToken?: string;
  subscription?: Subscription;
  company?: UserData;
};

export type Tech = {
  id: number;
  name: string;
  email: string;
  username: string;
  canBill: boolean;
};

export type SuperVisor = {
  id: number;
  name: string;
  email: string;
  username: string;
};

export type EmployeeSlots = {
  user: Tech | SuperVisor;
};

export type Subscription = {
  trial: string;
  subscriptionPlan: string;
  employeeSlots: EmployeeSlots[];
};

export type UserDetails = {
  address: string;
  website: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  mobileNumber: string;
  pc3: string;
  pc4: string;
  city: string;
  activity: string;
  cae: string;
  coordinates: string;
  nif: string;
  role: string;
};

export type Maintenance = {
  id: number | null;
  createdAt: string;
  content: {
    responsiblePersonName: string;
    machine: Machine;
    maintenanceIdentification: MaintenanceIdentification;
    anomaliesAndCauses: string;
    usedMaterials: Material[];
    description: string;
    observationTech: string;
    clientInput: {
      observation: string;
      clientSignature: string;
    };
  };
  status: string;
  technician: Tech;
  client: Client;
  involvedTechnicians: InvolvedTechnician[];
};

export type Machine = {
  name: string;
  model: string;
  serialNumber: string;
};

export type MaintenanceIdentification = {
  description: string;
  warranty: boolean;
  type: MaintenanceType;
};

export type Material = {
  component: string;
  quantity: string;
};

export type InvolvedTechnician = {
  id?: number | null;
  technician: Tech | null;
  startTime: number;
  endTime: number;
  distance: number;
  otherExpenses: string;
};

export interface WorkingDay {
  date: Date;
  distance: number;
  otherExpenses: {
    description: string;
    value: number;
  };
  startTime: Date;
  endTime: Date;
}

export type Client = {
  id: number;
  name: string;
  email: string;
  address: string;
  phone: string;
  localizationGPS?: CoordinatesType;
  localizationAddress?: string;
  localizationCP?: string;
  nif?: string;
};

export type CoordinatesType = {
  latitude?: number;
  longitude?: number;
};

export enum MaintenanceType {
  SPORADIC = "Esporádica",
  PREVENTIVE = "Preventiva",
  PERMANENT = "Permanente",
}

export interface TicketAttachment {
  id: number;
  path: string;
}

export interface ChatMessage {
  id: number;
  message: string;
  createdAt: string;
  user: {
    id: number;
    name: string;
  };
  attachments: TicketAttachment[];
}

export interface SupportChat {
  id: number;
  messages: ChatMessage[];
}

export interface TicketMetrics {
  id: number;
  responseTimeMinutes?: number;
  resolutionTimeMinutes?: number;
}

export interface Ticket {
  id: number;
  title: string;
  type: string; 
  status: string; 
  user: {
    id: number;
    name: string;
  };
  chat: SupportChat;
  metrics: TicketMetrics;
  createdAt: string;
  updatedAt: string;
}


export enum TicketStatus {
  OPEN = "Aberto",
  IN_PROGRESS = "Em Progresso",
  REVIEW = "Revisão",
  RESOLVED = "Resolvido",
  CLOSED = "Fechado",
}

export enum TicketPriority {
  LOW = "Low",
  MEDIUM = "Medium",
  HIGH = "High",
  CRITICAL = "Critical",
}

export enum TicketType {
  BUG = "Reportar Problema",
  FEATURE_REQUEST = "Nova Funcionalidade",
  IMPROVEMENT = "Sugestão de melhoria",
  OTHER = "Outro",
}
