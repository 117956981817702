import { useLocation, useNavigate } from "react-router-dom";
import { useUserState } from "./useUserState";
import { Form, Formik } from "formik";
import UserInfoHeader from "../../components/UserInfoHeader";
import ExpandableSection from "../../components/expandable-section/ExpandableSection";
import { useUserActions } from "./useUserActions";
import MaintenanceTable from "../../components/maintenance";
import ExtraSection from "../../components/ExtraSection";
import { DetailsSection } from "../../components/Details";
import { Role } from "../../@types/users";

function User() {
  const location = useLocation();

  const selectedUser = parseInt(
    location.pathname.split("/")[location.pathname.split("/").length - 1]
  );

  const navigate = useNavigate();

  const {
    user,
    setUser,
    expandedSections,
    setExpandedSections,
    filteredUserInfoData,
    setFilteredUserInfoData,
  } = useUserState();

  const { handleToggleSection, refetch } = useUserActions({
    selectedUser,
    user,
    setUser,
    setExpandedSections,
    setFilteredUserInfoData,
  });

  console.log(filteredUserInfoData);
  return (
    <>
      <Formik initialValues={{}} onSubmit={() => navigate("/users")}>
        {({ handleChange }) => (
          <Form>
            <div className="mb-8">
              <button
                onClick={() => null}
                className="bg-white border border-black text-black py-1 px-2 rounded-md"
              >
                Back
              </button>
             
              {user && (
                <>
                  <div className="flex">
                    {filteredUserInfoData && (
                      <div className="w-full p-4">
                        <UserInfoHeader
                          filteredUserInfoData={filteredUserInfoData}
                        />
                      </div>
                    )}
                  </div>

                  <div>
                    <ExpandableSection
                      title="Details"
                      expanded={expandedSections.details}
                      onToggle={() => handleToggleSection("details")}
                    >
                      {filteredUserInfoData ? (
                        <DetailsSection
                          userData={filteredUserInfoData}
                          refetch={refetch}
                        />
                      ) : (
                        <p className="text-red-900">Details not available</p>
                      )}
                    </ExpandableSection>

                    <ExpandableSection
                      title="Maintenances"
                      expanded={expandedSections.maintenance}
                      onToggle={() => handleToggleSection("maintenance")}
                    >
                      <MaintenanceTable data={filteredUserInfoData} />{" "}
                    </ExpandableSection>

                    <ExpandableSection
                      title="Extras"
                      expanded={expandedSections.extra}
                      onToggle={() => handleToggleSection("extra")}
                    >
                      {filteredUserInfoData ? (
                        <>
                          <ExtraSection
                            title="Extra"
                            filteredUserInfoData={filteredUserInfoData}
                          />{" "}
                        </>
                      ) : (
                        <p className="text-red-900">Extras not available</p>
                      )}
                    </ExpandableSection>
                  </div>
                </>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default User;
