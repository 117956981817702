import axios, { AxiosResponse } from "axios";
import { IUser } from "../@types/users";

export const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
})

export async function getLoggedUser(token: string | null): Promise<IUser | null> {
    let user;
    try {
         user = (await axiosInstance.get('/users/logged-in', { headers: { Authorization: `Bearer ${token}` } })).data.user;
    } catch(e) {
        console.log("Usable to get logged user");
    }
    return user;
}

export async function login(userInfo: { email: string, password: string }): Promise<AxiosResponse<any>> {
    return await axiosInstance.post('/user-admin/signin', {}, { auth: { username: userInfo.email, password: userInfo.password } })
}


export async function getAdmins(): Promise<AxiosResponse<any>> {
    return await axiosInstance.post('/user-admin/admins', {}, {})
}