import { useQuery } from "@tanstack/react-query";
import { getStatistics } from "../../services/user-admin.service";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  LabelList,
} from "recharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface User {
  createdAt: string;
}

interface UserCountByDay {
  date: string;
  count: number;
}

interface MaintenanceDataCompany {
  companyId: string;
  companyName: string;
  maintenanceCount: number;
}

interface MaintenanceDataTechnician {
  technicianId: string;
  technicianName: string;
  maintenanceCount: number;
}

interface AggregatedCompanyData {
  companyName: string;
  maintenanceCount: number;
}

interface AggregatedTechnicianData {
  technicianName: string;
  maintenanceCount: number;
}

function Statistics() {
  const navigate = useNavigate();

  const [selectedCompany, setSelectedCompany] = useState<
    string | number | undefined
  >("");
  const [selectedTechnician, setSelectedTechnician] = useState<
    string | number | undefined
  >("");
  const [techniciansByCompany, setTechniciansByCompany] = useState<any[]>([]);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [companyOptions, setCompanyOptions] = useState<any[]>([]);
  const [technicianOptions, setTechnicianOptions] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  );
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [chartVisible, setChartVisible] = useState(false); // State for chart visibility

  const [chartVisibleNewUsers, setChartVisibleNewUsers] = useState(false); // State for chart visibility
  const [startDateNewUsers, setStartDateNewUsers] = useState<Date | null>(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  );
  const [endDateNewUsers, setEndDateNewUsers] = useState<Date | null>(
    new Date()
  );

  const {
    data: statisticsData,
    isLoading,
    error,
  } = useQuery(["statisticsData"], getStatistics);

  useEffect(() => {
    if (statisticsData) {
      const { maintenancesByCompany, maintenancesByTechnician } =
        statisticsData;

      // Set unique company options from maintenancesByCompany
      const uniqueCompanies = Array.from(
        new Set(maintenancesByCompany.map((item: any) => item.companyId))
      ).map((companyId) => ({
        companyId,
        companyName: maintenancesByCompany.find(
          (item: any) => item.companyId === companyId
        )?.companyName,
      }));
      setCompanyOptions(uniqueCompanies);

      // Filter technicians based on the selected company
      let filteredTechnicians = maintenancesByTechnician;

      if (selectedCompany) {
        const technicianIdsForCompany = new Set(
          maintenancesByCompany
            .filter((item: any) => item.companyId === Number(selectedCompany))
            .map((item: any) => item.technicianId)
        );

        filteredTechnicians = maintenancesByTechnician.filter((item: any) =>
          technicianIdsForCompany.has(item.technicianId)
        );
      }

      // Set technician options
      const uniqueTechnicians = Array.from(
        new Set(filteredTechnicians.map((item: any) => item.technicianId))
      ).map((technicianId) => ({
        technicianId,
        technicianName: filteredTechnicians.find(
          (item: any) => item.technicianId === technicianId
        )?.technicianName,
      }));
      setTechnicianOptions(uniqueTechnicians);

      // Determine the earliest and latest dates from the data
      const allDates = maintenancesByCompany.map(
        (item: any) => new Date(item.createdAt)
      );
      const minDate = new Date(Math.min(...allDates));
      const maxDate = new Date(Math.max(...allDates));

      // Use the provided startDate or default to the earliest date in the data
      const startDateRange = startDate ? new Date(startDate) : minDate;
      // Use the provided endDate or default to today’s date
      const endDateRange = endDateNewUsers
        ? new Date(endDateNewUsers)
        : maxDate;

      // Define a utility function to get an array of all dates between two dates
      const getAllDatesInRange = (start: Date, end: Date) => {
        const dates: string[] = [];
        const currentDate = new Date(start);
        while (currentDate <= end) {
          dates.push(new Date(currentDate).toLocaleDateString());
          currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
      };

      // Get all dates within the selected range
      const allDatesInRange = getAllDatesInRange(startDateRange, endDateRange);

      // Filter and group data based on selected company and technician
      let filteredData = maintenancesByCompany.filter(
        (item: any) =>
          (!selectedCompany || item.companyId === Number(selectedCompany)) &&
          (!selectedTechnician ||
            item.technicianId === Number(selectedTechnician))
      );

      // Apply date range filter if dates are selected
      filteredData = filteredData.filter(
        (item: any) =>
          new Date(item.createdAt).getTime() >= startDateRange.getTime() &&
          new Date(item.createdAt).getTime() <= endDateRange.getTime()
      );

      // Group data by day
      const groupedByDay = filteredData.reduce((acc: any, item: any) => {
        const dateKey = new Date(item.createdAt).toLocaleDateString(); // Group by date only

        if (!acc[dateKey]) {
          acc[dateKey] = {
            date: dateKey,
            maintenanceCount: 0,
          };
        }
        acc[dateKey].maintenanceCount += 1;

        return acc;
      }, {});

      // Create data array with all dates
      const formattedGroupedData = allDatesInRange.map((date) => ({
        date,
        maintenanceCount: groupedByDay[date]?.maintenanceCount || 0,
      }));

      setFormattedData(formattedGroupedData);
    }
  }, [
    statisticsData,
    selectedCompany,
    selectedTechnician,
    startDate,
    endDateNewUsers,
  ]);

  if (isLoading) {
    return <div className="p-4">Loading...</div>;
  }

  if (error || !statisticsData) {
    return <div className="p-4">Error loading data...</div>;
  }

  const {
    totalUsersExcludingAdmin,
    companyUsersCount,
    technicianUsersCount,
    supervisorUsersCount,
    maintenancesPerDay,
    usersByCompany,
    maintenancesByCompany,
    maintenancesStatusByCompany,
    maintenancesByTechnician,
    maintenancesStatusByTechnician,
    submittedMaintenancesCount,
    createdMaintenancesCount,
    totalClientsCount,
    clientsByCompany,
    totalUsersOnline,
  } = statisticsData;

  const transformAndFilterUserData = (
    users: User[],
    startDate: Date | null,
    endDate: Date | null
  ): UserCountByDay[] => {
    // Extract and normalize dates to YYYY-MM-DD format
    const normalizeDate = (date: Date) => date.toISOString().split("T")[0];

    const allUserDates = users.map((user) =>
      normalizeDate(new Date(user.createdAt))
    );

    // Determine the earliest and latest dates
    const minDate = new Date(
      Math.min(...allUserDates.map((date) => new Date(date).getTime()))
    );
    const maxDate = new Date(
      Math.max(...allUserDates.map((date) => new Date(date).getTime()))
    );

    // Use provided startDate or default to minDate
    const start = startDate
      ? normalizeDate(new Date(startDate))
      : normalizeDate(minDate);
    // Use provided endDate or default to maxDate
    const end = endDate
      ? normalizeDate(new Date(endDate))
      : normalizeDate(maxDate);

    // Generate all dates in the range
    const getAllDatesInRange = (start: string, end: string) => {
      const dates: string[] = [];
      const currentDate = new Date(start);
      const endDate = new Date(end);

      while (currentDate <= endDate) {
        dates.push(normalizeDate(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return dates;
    };

    const allDatesInRange = getAllDatesInRange(start, end);

    // Count users per day
    const userCountsByDay: { [key: string]: number } = {};

    users.forEach((user) => {
      const userDate = normalizeDate(new Date(user.createdAt));
      if (!userCountsByDay[userDate]) {
        userCountsByDay[userDate] = 0;
      }
      userCountsByDay[userDate] += 1;
    });

    // Create data array with all dates, even those with zero users
    const result = allDatesInRange.map((date) => ({
      date,
      count: userCountsByDay[date] || 0,
    }));

    return result.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );
  };

  // Example usage
  const userChartData = transformAndFilterUserData(
    totalUsersExcludingAdmin,
    startDateNewUsers,
    endDateNewUsers
  );

  const aggregateDataCompany = (data: MaintenanceDataCompany[]) => {
    return data.reduce<Record<string, AggregatedCompanyData>>((acc, { companyId, companyName, maintenanceCount }) => {
      const count = Number(maintenanceCount);
      if (acc[companyId]) {
        acc[companyId].maintenanceCount += count;
      } else {
        acc[companyId] = {
          companyName,
          maintenanceCount: count,
        };
      }
      return acc;
    }, {});
  };
  
  const aggregatedDataCompany = Object.entries(aggregateDataCompany(maintenancesByCompany)).map(([companyId, value]) => {
    // Use type assertion to specify the expected type
    const { companyName, maintenanceCount } = value as AggregatedCompanyData;
    return {
      companyId,
      companyName,
      maintenanceCount,
    };
  });
  
  const aggregateDataTechnician = (data: MaintenanceDataTechnician[]) => {
    return data.reduce<Record<string, AggregatedTechnicianData>>((acc, { technicianId, technicianName, maintenanceCount }) => {
      const count = Number(maintenanceCount);
      if (acc[technicianId]) {
        acc[technicianId].maintenanceCount += count;
      } else {
        acc[technicianId] = {
          technicianName,
          maintenanceCount: count,
        };
      }
      return acc;
    }, {});
  };
  
  const aggregatedDataTechnician = Object.entries(aggregateDataTechnician(maintenancesByTechnician)).map(([technicianId, value]) => {
    // Use type assertion to specify the expected type
    const { technicianName, maintenanceCount } = value as AggregatedTechnicianData;
    return {
      technicianId,
      technicianName,
      maintenanceCount,
    };
  });

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-6">Statistics Overview</h1>

      <div className="mt-4">
        <button
          type="button"
          onClick={() => setChartVisible(!chartVisible)}
          className="bg-gray-500 border border-black text-white py-1 px-2 mb-4 mr-4 rounded-md hover:bg-blue-500"
        >
          {chartVisible
            ? "Hide Chart: Maintenances by Day and User"
            : "Show Chart:  Maintenances by Day and User"}
        </button>
      </div>

      <div className="mt-4">
        <button
          type="button"
          onClick={() => setChartVisibleNewUsers(!chartVisibleNewUsers)}
          className="bg-gray-500 border border-black text-white py-1 px-2 mb-4 mr-4 rounded-md hover:bg-blue-500"
        >
          {chartVisibleNewUsers
            ? "Hide Chart: Registered Users by Day"
            : "Show Chart:  Registered Users by Day"}
        </button>
      </div>

      {chartVisibleNewUsers && (
        <>
          <div className="mb-6">
            <h1 className="font-bold text-2xl text-blue-800 mb-4">
              Registered Users by Day
            </h1>

            {/* Date Range Picker */}
            <div className="flex items-center mt-4">
              <label className="mr-4 font-semibold text-gray-700">
                Start Date:
              </label>
              <DatePicker
                selected={startDateNewUsers}
                onChange={(date: Date | null) => setStartDateNewUsers(date)}
                dateFormat="yyyy-MM-dd"
                className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="flex items-center mt-4">
              <label className="mr-4 font-semibold text-gray-700">
                End Date:
              </label>
              <DatePicker
                selected={endDateNewUsers}
                onChange={(date: Date | null) => setEndDateNewUsers(date)}
                dateFormat="yyyy-MM-dd"
                className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>

          <ResponsiveContainer width="100%" height={500}>
            <LineChart
              data={userChartData}
              margin={{ top: 15, right: 20, left: 10, bottom: 60 }}
            >
              <CartesianGrid strokeDasharray="3 3" />

              <XAxis
                dataKey="date"
                tick={{ fill: "#666", fontSize: 12, fontWeight: "bold" }}
                tickMargin={10}
                height={50} // Adjust height for label space
              >
                <Label
                  value="Days"
                  offset={0}
                  position="insideBottom"
                  style={{
                    textAnchor: "middle",
                    fill: "#666",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                />
              </XAxis>
              <YAxis
                tickCount={10} // Adjust based on your range
                interval="preserveStartEnd"
                domain={[0, "dataMax"]} // Set domain from 0 to the maximum value of the data
                allowDecimals={false} // Display whole numbers only
                tickSize={10}
                tick={{ fill: "#666", fontSize: 16, fontWeight: "bold" }} // Customize Y-axis ticks
                tickFormatter={(tick) => `${tick}`} // Format the ticks if necessary
              >
                <Label
                  value="Registered Users"
                  angle={-90}
                  position="insideLeft"
                  style={{
                    textAnchor: "middle",
                    fill: "#666",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                />
              </YAxis>

              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="count"
                name="Number of Users" // Custom label for the legend
                stroke="#8884d8"
                strokeWidth={3} // Adjust thickness of the line
                dot={{ r: 8 }} // Adjust size of the dots
                activeDot={{ r: 12 }} // Adjust size of the active dots
              />
            </LineChart>
          </ResponsiveContainer>
        </>
      )}

      {chartVisible && (
        <>
          <div className="mb-6">
            <h1 className="font-bold text-2xl text-blue-800 mb-4">
              Number of Maintenances by Day and User
            </h1>

            {/* Company Dropdown */}
            <div className="flex items-center mb-4">
              <label className="mr-4 font-semibold text-gray-700">
                Company:
              </label>
              <select
                value={selectedCompany}
                onChange={(e: any) => {
                  setSelectedCompany(e.target.value);
                  setSelectedTechnician(""); // Reset technician selection
                }}
                className="border border-gray-300 rounded-md p-2 pr-10 focus:outline-none focus:ring-2 focus:ring-blue-500"
                style={{ width: "auto", minWidth: "200px" }} // Adjust minWidth as needed
              >
                <option value="">All</option>
                {companyOptions.map(({ companyId, companyName }: any) => (
                  <option key={companyId} value={companyId}>
                    {companyName}
                  </option>
                ))}
              </select>
            </div>

            {/* Technician Dropdown */}
            {selectedCompany && (
              <div className="flex items-center">
                <label className="mr-4 font-semibold text-gray-700">
                  Technician:
                </label>
                <select
                  value={selectedTechnician}
                  onChange={(e: any) => setSelectedTechnician(e.target.value)}
                  className="border border-gray-300 rounded-md p-2 pr-10 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  style={{ width: "auto", minWidth: "200px" }} // Adjust minWidth as needed
                >
                  <option value="">All</option>
                  {technicianOptions.map(
                    ({ technicianId, technicianName }: any) => (
                      <option key={technicianId} value={technicianId}>
                        {technicianName}
                      </option>
                    )
                  )}
                </select>
              </div>
            )}
            {/* Date Range Picker */}
            <div className="flex items-center mt-4">
              <label className="mr-4 font-semibold text-gray-700">
                Start Date:
              </label>
              <DatePicker
                selected={startDate}
                onChange={(date: Date | null) => setStartDate(date)}
                dateFormat="yyyy-MM-dd"
                className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="flex items-center mt-4">
              <label className="mr-4 font-semibold text-gray-700">
                End Date:
              </label>
              <DatePicker
                selected={endDate}
                onChange={(date: Date | null) => setEndDate(date)}
                dateFormat="yyyy-MM-dd"
                className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>

          <ResponsiveContainer width="100%" height={500}>
            <LineChart
              data={formattedData}
              margin={{ top: 15, right: 20, left: 10, bottom: 60 }} // Increased bottom margin for X-axis label
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                tick={{ fill: "#666", fontSize: 12, fontWeight: "bold" }}
                tickMargin={10}
                height={50} // Adjust height for label space
              >
                <Label
                  value="Days"
                  offset={0}
                  position="insideBottom"
                  style={{
                    textAnchor: "middle",
                    fill: "#666",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                />
              </XAxis>
              <YAxis
                tickCount={10} // Adjust based on your range
                interval="preserveStartEnd"
                domain={[0, "dataMax"]} // Set domain from 0 to the maximum value of the data
                allowDecimals={false} // Display whole numbers only
                tickSize={10}
                tick={{ fill: "#666", fontSize: 16, fontWeight: "bold" }} // Customize Y-axis ticks
                tickFormatter={(tick) => `${tick}`} // Format the ticks if necessary
              >
                <Label
                  value="Number of Maintenances"
                  angle={-90}
                  position="insideLeft"
                  style={{
                    textAnchor: "middle",
                    fill: "#666",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                />
              </YAxis>
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="maintenanceCount"
                name="Number of Maintenances" // Custom label for the legend
                stroke="#8884d8"
                strokeWidth={3} // Adjust thickness of the line
                dot={{ r: 8 }} // Adjust size of the dots
                activeDot={{ r: 12 }} // Adjust size of the active dots
              />
            </LineChart>
          </ResponsiveContainer>
        </>
      )}
      {/* User Statistics */}
      <section className="mt-8 mb-8 border-4 border-red-900 rounded-md p-4">
        <h2 className="text-lg font-semibold mb-4 text-red-900">
          User Statistics
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <div className="bg-gray-100 p-4 rounded shadow">
            <h3 className="font-semibold text-sm text-gray-500">
              Total Users / (online)
            </h3>
            <p className="text-sm font-bold">
              {totalUsersExcludingAdmin.length} / (
              <span className="text-green-800"> {totalUsersOnline} </span> )
            </p>
          </div>
          <div className="bg-gray-100 p-4 rounded shadow">
            <h3 className="font-semibold text-sm text-gray-500">Companies</h3>
            <p className="text-sm font-bold">{companyUsersCount}</p>
          </div>
          <div className="bg-gray-100 p-4 rounded shadow">
            <h3 className="font-semibold text-sm text-gray-500">Technicians</h3>
            <p className="text-sm font-bold">{technicianUsersCount}</p>
          </div>
          <div className="bg-gray-100 p-4 rounded shadow">
            <h3 className="font-semibold text-sm text-gray-500">Supervisors</h3>
            <p className="text-sm font-bold">{supervisorUsersCount}</p>
          </div>
        </div>
      </section>
      {/* Maintenance Status Counts */}
      <section className="mb-8 border-4 border-green-900 rounded-md p-4">
        <h2 className="text-lg font-semibold mb-4 text-green-900">
          Maintenance Statistics
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 mb-8">
          <div className="bg-gray-100 p-4 rounded shadow">
            <h3 className="font-semibold text-sm text-gray-500">
              Submitted Maintenances
            </h3>
            <p className="text-sm font-bold">{submittedMaintenancesCount}</p>
          </div>
          <div className="bg-gray-100 p-4 rounded shadow">
            <h3 className="font-semibold text-sm text-gray-500">
              Created Maintenances
            </h3>
            <p className="text-sm font-bold">{createdMaintenancesCount}</p>
          </div>
        </div>
        {/* Maintenances by Company */}
        <section className="mb-8">
          <h2 className="text-md font-semibold mb-4 text-green-900">
            Maintenances by Company
          </h2>
          <table className="w-full bg-white border border-gray-300 rounded shadow mb-4">
            <thead className="bg-green-200">
              <tr>
                <th className="p-3 border-b border-gray-300 text-center text-sm">
                  Company ID
                </th>
                <th className="p-3 border-b border-gray-300 text-center text-sm">
                  Company Name
                </th>
                <th className="p-3 border-b border-gray-300 text-center text-sm">
                  Number of Maintenances
                </th>
              </tr>
            </thead>
            <tbody>
              {aggregatedDataCompany.map(
                ({ companyId, companyName, maintenanceCount }: any) => (
                  <tr key={companyId}>
                    <td
                      className="p-3 border-b border-gray-300 text-center text-sm hover:bg-gray-100 transition-colors duration-200 cursor-pointer"
                      onClick={() => {
                        navigate(`/users/${companyId}`);
                      }}
                    >
                      {companyId || "N/A"}
                    </td>
                    <td className="p-3 border-b border-gray-300 text-center text-sm">
                      {companyName}
                    </td>
                    <td className="p-3 border-b border-gray-300 text-center text-sm">
                      {maintenanceCount}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </section>

        {/* Maintenance Status by Company */}
        <section className="mb-8">
          <h2 className="text-md font-semibold mb-4 text-green-900">
            Maintenance Status by Company
          </h2>
          <table className="w-full bg-white border border-gray-300 rounded shadow mb-4">
            <thead className="bg-green-200">
              <tr>
                <th className="p-3 border-b border-gray-300 text-center text-sm ">
                  Company ID
                </th>
                <th className="p-3 border-b border-gray-300 text-center text-sm ">
                  Company Name
                </th>
                <th className="p-3 border-b border-gray-300 text-center text-sm">
                  Status
                </th>
                <th className="p-3 border-b border-gray-300 text-center text-sm">
                  Number of Maintenances
                </th>
              </tr>
            </thead>
            <tbody>
              {maintenancesStatusByCompany.map(
                ({ companyId, companyName, status, statusCount }: any) => (
                  <tr key={`${companyId}-${status}`}>
                    <td
                      className="p-3 border-b border-gray-300 text-center text-sm hover:bg-gray-100 transition-colors duration-200 cursor-pointer"
                      onClick={() => {
                        navigate(`/users/${companyId}`);
                      }}
                    >
                      {companyId || "N/A"}
                    </td>
                    <td className="p-3 border-b border-gray-300 text-center text-sm">
                      {companyName}
                    </td>
                    <td className="p-3 border-b border-gray-300 text-center text-sm">
                      {status}
                    </td>
                    <td className="p-3 border-b border-gray-300 text-center text-sm">
                      {statusCount}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </section>

        {/* Maintenances by Technician */}
        <section className="mb-8">
          <h2 className="text-md font-semibold mb-4 text-green-900">
            Maintenances by Technician
          </h2>
          <table className="w-full bg-white border border-gray-300 rounded shadow mb-4">
            <thead className="bg-green-200">
              <tr>
                <th className="p-3 border-b border-gray-300 text-center text-sm">
                  Technician ID
                </th>
                <th className="p-3 border-b border-gray-300 text-center text-sm">
                  Technician Name
                </th>
                <th className="p-3 border-b border-gray-300 text-center text-sm">
                  Number of Maintenances
                </th>
              </tr>
            </thead>
            <tbody>
              {aggregatedDataTechnician.map(
                ({ technicianId, technicianName, maintenanceCount }: any) => (
                  <tr key={technicianId}>
                    <td
                      className="p-3 border-b border-gray-300 text-center text-sm hover:bg-gray-100 transition-colors duration-200 cursor-pointer"
                      onClick={() => {
                        navigate(`/users/${technicianId}`);
                      }}
                    >
                      {technicianId}
                    </td>
                    <td className="p-3 border-b border-gray-300 text-center text-sm">
                      {technicianName}
                    </td>
                    <td className="p-3 border-b border-gray-300 text-center text-sm">
                      {maintenanceCount}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </section>

        {/* Maintenance Status by Technician */}
        <section className="mb-8">
          <h2 className="text-md font-semibold mb-4 text-green-900">
            Maintenance Status by Technician
          </h2>
          <table className="w-full bg-white border border-gray-300 rounded shadow">
            <thead className="bg-green-200">
              <tr>
                <th className="p-3 border-b border-gray-300 text-center text-sm">
                  Technician ID
                </th>
                <th className="p-3 border-b border-gray-300 text-center text-sm">
                  Technician Name
                </th>
                <th className="p-3 border-b border-gray-300 text-center text-sm">
                  Status
                </th>
                <th className="p-3 border-b border-gray-300 text-center text-sm">
                  Number of Maintenances
                </th>
              </tr>
            </thead>
            <tbody>
              {maintenancesStatusByTechnician.map(
                ({
                  technicianId,
                  technicianName,
                  status,
                  statusCount,
                }: any) => (
                  <tr key={`${technicianId}-${status}`}>
                    <td
                      className="p-3 border-b border-gray-300 text-center text-sm hover:bg-gray-100 transition-colors duration-200 cursor-pointer"
                      onClick={() => {
                        navigate(`/users/${technicianId}`);
                      }}
                    >
                      {technicianId}
                    </td>
                    <td className="p-3 border-b border-gray-300 text-center text-sm">
                      {technicianName}
                    </td>
                    <td className="p-3 border-b border-gray-300 text-center text-sm">
                      {status}
                    </td>
                    <td className="p-3 border-b border-gray-300 text-center text-sm">
                      {statusCount}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </section>
      </section>
      {/* Client Statistics */}
      <section className="mb-8 border-4 border-blue-900 rounded-md p-4">
        <h2 className="text-lg font-semibold mb-4 text-blue-900 ">
          Client Statistics
        </h2>
        <div className="bg-gray-100 p-4 rounded shadow mb-4">
          <h3 className="font-semibold text-sm text-gray-500">
            Total Clients Registered
          </h3>
          <p className="text-sm font-bold">{totalClientsCount}</p>
        </div>
        <h3 className="text-md font-semibold mb-4 text-blue-900">
          Clients by Company
        </h3>
        <table className="w-full bg-white border border-gray-300 rounded shadow">
          <thead className="bg-blue-200">
            <tr>
              <th className="p-3 border-b border-gray-300 text-center text-sm">
                Company ID
              </th>
              <th className="p-3 border-b border-gray-300 text-center text-sm">
                Company Name
              </th>
              <th className="p-3 border-b border-gray-300 text-center text-sm">
                Number of Clients
              </th>
            </tr>
          </thead>
          <tbody>
            {clientsByCompany.map(
              ({ companyId, companyName, clientCount }: any) => (
                <tr key={companyId}>
                  <td
                    className="p-3 border-b border-gray-300 text-center text-sm hover:bg-gray-100 transition-colors duration-200 cursor-pointer"
                    onClick={() => {
                      navigate(`/users/${companyId}`);
                    }}
                  >
                    {companyId || "N/A"}
                  </td>
                  <td className="p-3 border-b border-gray-300 text-center text-sm">
                    {companyName}
                  </td>
                  <td className="p-3 border-b border-gray-300 text-center text-sm">
                    {clientCount}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </section>
    </div>
  );
}

export default Statistics;
