import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { addInvoiceToMembership, createMembership, getAllMemberships } from '../../services/user-admin.service';

export const useMembershipActions = () => {
  const queryClient = useQueryClient();

  const createMembershipMutation = useMutation(createMembership, {
    onSuccess: () => {
      queryClient.invalidateQueries(['memberships']);
    },
  });

  const addInvoiceMutation = useMutation(addInvoiceToMembership, {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries(['membership', data.membershipId]);
    },
  });

  return {
    createMembershipMutation,
    addInvoiceMutation,
  };
};
